// in src/posts.js
import * as React from "react";
import { cloneElement , Confirm , useForm, useNotify, doStuff,  forwardRef, createRef } from 'react';

import {
    List, SimpleList, 
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    DeleteButton,
    Edit,
    Create,    
    ReferenceInput,
    SelectInput,
    TextInput,
    Button ,
    CreateButton ,
    ExportButton,
    TopToolbar,
    Toolbar,
    Pagination,
    FormTab,
    TabbedForm,
    ReferenceManyField,
    Title,
    FormDataConsumer,
    useListContext, SaveButton,
    Filter, required, ChipField,ReferenceArrayField, SingleFieldList, ArrayField, DateField, DateInput, ResourceContextProvider, NumberInput, Show, SimpleShowLayout, TabbedShowLayout, Tab,
    linkToRecord,useRefresh,useRedirect, ShowController, ShowView, BooleanInput
} from 'react-admin';

import Typography from '@mui/material/Typography';

import MenuItem from '@mui/material/MenuItem';

import { ListProps } from 'react-admin';

import { TreeWithDetails, NodeActions,  DeleteMenuItem , DeleteMenuItemWithConfirmation, SimpleForm} from '@react-admin/ra-tree';

import Grid from '@mui/material/Grid';

import { AccordionForm, AccordionSection ,AccordionFormPanel } from "@react-admin/ra-form-layout";

import { DualListInput } from '@react-admin/ra-relationships';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

import { Card, CardContent, CardHeader,useMediaQuery } from '@material-ui/core';
import IconEvent from '@material-ui/icons/Event';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import SaveIcon from '@material-ui/icons/GetApp';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { useFormState } from 'react-final-form';
import { useRecordContext } from 'ra-core';

import { UserAvatarIcon, UserFullNameTxt } from '../components/User';

import { GroupsQuotesList,GroupsQuotesEdit,GroupsQuotesCreate  } from './groups_quotes';
import { GroupsCardsList,DownloadDemoCard, GroupsCardsSendButton } from './groups_cards';

import { DocsList } from './docs';

import { subscriptionModule, subscriptionModuleOption  } from '../common/utils';
import { i18nProvider, useTranslate  } from '../common/language';
import { useStyles  } from '../common/styles';

import { UsersList  } from './../resources/users';
import { UsersGroupsResponsabilitiesList } from './../resources/users_groups_responsabilities';

import { NoPagination } from './../components/NoPagination';

import { SocialNetsList  } from './social_nets';

import { deepOrange, deepPurple } from '@mui/material/colors';

import { useLocation, Switch, Route } from 'react-router-dom';

import { alpha } from '@material-ui/core/styles';

import { FunctionField } from 'react-admin';


import { targets , modules, apiUrl, apiOrigin, apiDefaultLanguage } from './../common/constants';


import { MySearch  } from '../components/MySearch';



import { validateMaxLength, validateInteger, validateDouble  } from './../components/Validator';


export const GroupsList = (props: ListProps) => {
    const translate = useTranslate();    
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    console.log('GroupsList');
    console.log(props);
    
    const onSelect = () => {
      
    };

    return (
        
        <div>
        {props.resource === "groups-tree-contacts" ? <ContactsHeader /> : null }
        <TreeWithDetails                
                title={translate('groups.title')}
                create={GroupsCreate}
                edit={GroupsEdit}
                nodeActions={<MyActions />}
                titleField="name"
                allowMultipleRoots
                onSelect={onSelect}
                draggable 
                linkTo="edit"
                {...props}
            />
        </div>
        
    );
}

const MyActions = (props) => (
  <NodeActions {...props} >
    <DeleteMenuItemWithConfirmation />
  </NodeActions>
);

const ContactsHeader = props => {
    const translate = useTranslate();        
    return (
            <>
            
            <Card fullWidth className="contacts_fac_header">
                <CardHeader title={translate('contacts.title')} />
                <CardContent>
                    <Grid container spacing={12}>
                        <Grid item xs={9} align="justify">
                            <div className="" dangerouslySetInnerHTML={{ __html: translate('contacts.description') }} /> 
                        </Grid>
                        <Grid item xs={3} align="right">
                            <MySearch targets={["contacts_fac"]} />                  
                        </Grid>
                    </Grid>
                </CardContent>                
            </Card>            
            </>
    );
}

export const GroupsEdit = props => {

    const translate = useTranslate();        
    const classes = useStyles();
    const record = useRecordContext();
    const today = new Date().toISOString().slice(0, 10);
    
    
    let redirect = "/" + props.resource + "/" + props.id;
    let redirectCard = "/" + props.resource + "/" + props.id + '/card';
    let resource = props.resource;
            
    return (
    
    <ShowController {...props}>
        {controllerProps => 
    
    <Show {...props} {...controllerProps} >
    {controllerProps.record && 
    <>
    
    <Typography variant="h5" className="groups_title grey8"><TextField source="name" textAlign="center"/></Typography>      
                
    <TabbedShowLayout>
           
            <Tab
                label={translate('groups.tab_users')}
                contentClassName={classes.tab}
                fullWidth
                path=""
            >                 
                
                <UsersList group={controllerProps.record.id} group_public_id={controllerProps.record.public_id} resource_name={resource} />

                <br />
                
            </Tab>
            
            { resource=='groups-tree' && controllerProps.record.has_more_config && !subscriptionModule(modules.RECERCAT) &&
            <Tab
                label={translate('groups.tab_responsabilities')}
                contentClassName={classes.tab}
                path="responsabilities"     
            >   
                <UsersGroupsResponsabilitiesList groups_id={controllerProps.record.id} />
                <br/>
                
            </Tab>  
            }

            { resource=='groups-tree' && controllerProps.record.has_more_config && !subscriptionModule(modules.RECERCAT) &&
            <Tab
                label={translate('groups.tab_quotes')}
                contentClassName={classes.tab}
                path="quotes"
            >        
                <GroupsQuotesList group_id={controllerProps.record.id} />
                <br /><br/>
            </Tab>
            }
            
            { resource=='groups-tree' && controllerProps.record.has_more_config && !subscriptionModule(modules.RECERCAT) &&
            <Tab
                label={translate('groups.tab_docs')}
                contentClassName={classes.tab}
                path="docs"
            >             
                
                <DocsList target={targets.GROUP} items_id={controllerProps.record.id} />
                
                <br />
                
            </Tab>
            }
          
            { resource=='groups-tree' && !subscriptionModule(modules.RECERCAT) &&
            <Tab
                label={translate('groups.group')}
                contentClassName={classes.tab}
                path="edit"
            >           
                <Edit title="" id={controllerProps.record.id} mutationMode="pessimistic"  >
                    <SimpleForm {...props} toolbar={<CustomToolbar />} redirect={redirect} defaultValues={{ card_digital: true }}>
                        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>          
                                { controllerProps.record.is_editable ? 
                                <TextInput fullWidth source="name" label={translate('groups.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />                                          
                                :
                                <TextInput fullWidth source="name" label={translate('groups.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} disabled />                                          
                                }    
                            </Grid>
                        </Grid>  
                        
                        { subscriptionModule(modules.DIGITAL_CARDS) ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>          
                                <BooleanInput fullWidth source="card_digital" label={translate('groups.card_digital')} />  
                            </Grid>
                        </Grid>    
                        : null }
                        
                        
                        { controllerProps.record.has_more_config ?
                        <AccordionSection className="accordion" label={translate('users.tab_social_nets')} fullWidth defaultExpanded={true}>
                            <SocialNetsList target={targets.GROUP} items_id={controllerProps.record.id} />
                            <br />
                        </AccordionSection>
                        : null }
                    </SimpleForm>
                </Edit>
                <br />
            </Tab>
            }
            
            { resource=='groups-tree' && subscriptionModule(modules.DIGITAL_CARDS) && controllerProps.record.card_digital && 
            <Tab
                label={translate('groups.card')}
                contentClassName={classes.tab}
                path="card"
            >   
                <GroupsCardsList groups_id={controllerProps.record.id} />
            </Tab>
            }

    </TabbedShowLayout >
    
    </>
    }
    </Show>
    }
    </ShowController>

);
};


/*
{controllerProps.record && controllerProps.record.has_more_config && alert(controllerProps.record.has_more_config) && 
{controllerProps.record && controllerProps.record.is_editable && 
*/

const customToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={customToolbarStyles()}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);

export const GroupsCreate = props => {    
    const translate = useTranslate();   
    const redirect = useRedirect();
    
    const refresh = useRefresh();
    const onSuccess = ({ data }) => {
        refresh();
        redirect('list', '/groups-tree');
    };

    return (
    <Create {...props} onSuccess={onSuccess}>
        <SimpleForm 
            redirect="edit" 
            initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        >
            
            <TextInput fullWidth source="name" label={translate('groups.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />

        </SimpleForm>
    </Create>
);
};

/*const GroupsFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>
        <TextInput label={translate('common.search')} source="q" alwaysOn   validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />        
    </Filter>
);
};*/


/*const ListActions = (props) => (
    <TopToolbar>
        {cloneElement(props.filters, { context: 'button' })}
        <CreateButton/>
        <ExportButton />        
    </TopToolbar>
);*/

/*
const ElementTitle = ({ record }) => {
    const translate = useTranslate(); 
    return <span>{translate('groups.group')} {record ? `"${record.name}"` : ''}</span>;
};*/





