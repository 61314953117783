import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useRefresh, useTranslate, FileInput, FileField
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import PublishIcon from '@mui/icons-material/Publish';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { subscriptionModule  } from '../common/utils';
import {modules } from './../common/constants';

import {maxDocFileSize} from './../common/constants';

export const ImportButton = ({ target, items_id , label, icon, btnClassname, group_public_id, ...props }) => {
  
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('docs/import');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  let allowableAllDocs = false;
  let labelInputFile = '';    
  let maxDocFileSizeMin = maxDocFileSize / 1000000;
  labelInputFile = translate('docs.fieldExcel').replace('%size%',maxDocFileSizeMin);
    
  let filename = '/docs/plantilla_persones.xlsx';
  if ( subscriptionModule(modules.FEDERATION) && group_public_id === 'SOCIS')
    filename = '/docs/plantilla_persones_fac.xlsx';
  if ( subscriptionModule(modules.RECERCAT) )
    filename = '/docs/plantilla_persones_recercat.xlsx';

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: (data) => {
          setShowDialog(false);
          notify(translate('docs.imported') );
          refresh();
        },
        onFailure: ( error ) => {                    
          notify(error.message, 'error');
        }
      }
    );
  };
    
  const handleSubmit2 = async values => {
        
  };

  return (
    <>           
               
      <Button onClick={handleClick} label={translate('users.import')} className={btnClassname} >
        <PublishIcon />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users.import')}
      >
        <DialogTitle>{translate('users.import_title')}</DialogTitle>

        <FormWithRedirect
          resource="docs"
          save={handleSubmit}
          onSuccess={handleSubmit2}
          initialValues={{ associations_id : localStorage.getItem('associations_id'), target: target, items_id: items_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent> 
                <a href={filename} target="_blank">{translate('users.import_template')}</a>
                <br /><br />
                <FileInput source="image" label={labelInputFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" validate={required()} maxSize={maxDocFileSize} >
                  <FileField source="image" title="title" />
                </FileInput>                                
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
            
    </>
  );
};
