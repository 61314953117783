// in src/posts.js
import React, { useState } from 'react';
import { cloneElement , Confirm , useForm, useNotify, doStuff, Component } from 'react';
import { FC, createElement } from 'react';

import {
    List, SimpleList, 
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    Button ,
    CreateButton ,
    ExportButton,
    TopToolbar,
    Pagination,
    FormTab,
    TabbedForm,
    ReferenceManyField,
    Title,
    FormDataConsumer,
    useListContext,
    Filter, required, ChipField,ReferenceArrayField, SingleFieldList, ArrayField,DateField ,DateInput,NumberInput,RadioButtonGroupInput,BooleanInput,SelectField,
    useGetOne , FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton, useDataProvider, Loading, Error, NumberField,
    CheckboxGroupInput,
    ReferenceArrayInput,
    SelectArrayInput

    
} from 'react-admin';

import { useEffect } from 'react';

import { DualListInput } from '@react-admin/ra-relationships';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

import { Card, CardContent, CardHeader,useMediaQuery } from '@material-ui/core';
import IconEvent from '@material-ui/icons/Event';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { useFormState } from 'react-final-form';

import { i18nProvider, useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';

import { green, orange, deepOrange } from '@mui/material/colors';

import { UserAvatarField, UserFullNameTxt } from '../components/User';

import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@mui/icons-material/Person';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Listt from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceBigIcon from '@material-ui/icons/AccountBalance';
import EuroIcon from '@mui/icons-material/Euro';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import { RemittancesUsersToolbar, RemittancesUsersEditButton, RemittancesUsersRefreshButton, RemittancesUsersCreateButton, RemittancesUsersEditButton2 } from './../components/RemittancesUsers';

import { DownloadSEPAButton } from './../components/DownloadSEPAButton';
import { DownloadPDFButton } from './../components/DownloadPDFButton';
import { Empty } from './../components/Empty';
import { SendEmailIconStatus } from './../components/Emails';
import { targetsEmails } from './../common/constants';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger, validateDouble  } from './../components/Validator';


export const RemittancesList = (props) => {
    const translate = useTranslate();    
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <><br />
        <List {...props} 
        sort={{ field: 'date_creation', order: 'DESC' }} 
        actions={<ListActionsRemittancesList/>}    
        filter={{ associations_id : localStorage.getItem('associations_id') }}  
        bulkActionButtons={false}  
        empty={<Empty title={translate('remittances.no_remittances_users_title')} icon={AccountBalanceBigIcon} />} 
        filters={<RemittancesFilter />} 
        >        
                <Datagrid 
                rowClick="edit">  
                    <Avatar><DescriptionIcon/></Avatar>   
                    <DateField source="date_creation" label={translate('remittances.date_creation')} sortable={false} />                
                    <TextField source="ibans_name" label={translate('ibans.name_create_remittance')} sortable={false} />  
                    <TextField source="name" label={translate('common.name')} sortable={false} />                      
                    <TextField source="count_users" label={translate('remittances.count_users')} sortable={false} />                  
                    <NumberField source="sum_quantity" label={translate('remittances.sum_quantity')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />  
                </Datagrid>        
        </List>
        </>
    );
}

const RemittancesFilter = (props) => {
    const translate = useTranslate(); 
    return (
    
    <Filter {...props}>  
        <ReferenceInput 
            fullWidth
            sort={{ field: 'priority', order: 'ASC' }} 
            perPage={1000} 
            label={translate('ibans.name_create_remittance')} 
            source="ibans_id" 
            reference="ibans"
            filter={{ associations_id : localStorage.getItem('associations_id') }}
            alwaysOn
            className="form-300" 
        >
            <SelectInput optionText="name" optionValue="id" optionText={optionRenderer} resettable />
        </ReferenceInput> 
    </Filter>
);
};

const ListActionsRemittancesList = (props) => {
    const translate = useTranslate(); 
    return (
    <TopToolbar>     
        <CreateButton label={translate('remittances.create_remittance')} />      
    </TopToolbar>
    );
};

const UserLinkButton = ({ record, ...props }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const handleClick = () => {
            window.open('/#/users/' + record.users_id + '/show' , '_blank');
    };
    
    if (!record.users_deleted)
        return <Button {...props} label="" onClick={handleClick} startIcon={<PersonIcon />} />;
    else
        return <span />;
};

export const RemittancesEdit  = ({ id, ...props }) => {

    const translate = useTranslate();        
    const classes = useStyles();

    let strRedirect =  `/remittances/${id}/receipts`;    

    return (

    <Edit 
        title={<ElementTitle />} 
        {...props} 
        id={id}
        mutationMode="pessimistic"
    > 
        <SimpleForm {...props} redirect="edit" >
            
            <>  
            
            <FormDataConsumer>
                {({ formData, ...rest }) => 
                
                <>
                
                <Grid container spacing={2}>
                    <Grid item xs={12}>                    
                        <Typography variant="h5" className="grey">{formData.name}</Typography>
                    </Grid>     
                    <Grid item xs={5}>   
                        <ReferenceInput 
                            fullWidth
                            sort={{ field: 'priority', order: 'ASC' }} 
                            perPage={1000} 
                            label={translate('ibans.name_create_remittance')} 
                            source="ibans_id" 
                            reference="ibans"
                            filter={{ associations_id : localStorage.getItem('associations_id') }}
                        >
                            <SelectInput optionText="name" optionValue="id" validate={required()} optionText={optionRenderer} />
                        </ReferenceInput> 
                    </Grid>  
                    <Grid item xs={4}>                    
                        <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>
                    <Grid item xs={3}>
                        <DateInput fullWidth source="date_creation" label={translate('remittances.date_creation')} disabled />
                    </Grid>                
                </Grid>   

                <RemittanceResume id={id} date_creation={formData.date_creation} />
                <ResourceContextProvider value="remittances_users" >
                <List 
                syncWithLocation 
                basePath="/remittances_users" 
                filter={{ remittances_id : id }}  
                title=""
                bulkActionButtons={false}                
                actions={<RemittancesUsersToolbar remittances_id={id} date_creation={formData.date_creation} /> }                  
                filters={<RemittancesUsersFilter />} 
                filterDefaultValues={ {status : -1, payment_system : -1, q: "" }}
                perPage="25"
                >

                    <Datagrid                            
                        expand={<RemittancesUsersItemsDetails edit={true} />}                                                  
                    >   
                        <UserLinkButton />
                        <NumberField source="id" label={translate('remittances.ref')} sortable={false}  />
                        <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={record} /> } sortable={false}  />
                        <TextField label={translate('users.email')} source="email" sortable={false}  />
                        <NumberField source="quantity" label={translate('remittances.quantity')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />
                        <FunctionField label={translate('remittances.payment_system')} render={RenderPaymentSystem} sortable={false}  />
                        <FunctionField label={translate('remittances.status')} render={RenderStatus} sortable={false}  />
                        <FunctionField label="" render={record => 
                            <SendEmailIconStatus record={record} target={targetsEmails.RECEIVERETURNED}  />
                        } sortable={false}  />
                        <TextField source="comments" label={translate('remittances.comments')} sortable={false}  />
                        <RemittancesUsersEditButton label=""/>
                        
                        <DeleteButton undoable={false} redirect={strRedirect} label=""/>
                    </Datagrid>

                </List> 
                </ResourceContextProvider>
                </>

            }
            </FormDataConsumer>

            </>

        </SimpleForm> 
    </Edit>
);
};

const useStylesRemittanceInfo = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        marginBottom: 20
    },
    main: (props: Props) => ({
        overflow: 'inherit',
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#FF6600',
        },
    }),
    title: {},
}));

const RemittanceResume = ({ id, date_creation, ...props}) => {
    const classes = useStylesRemittanceInfo(props);
    const translate = useTranslate(); 
    const dataProvider = useDataProvider();
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        dataProvider.getOne('remittances/info', { id: id })
            .then(({ data }) => {                
                setInfo(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
                alert(error);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!info) return null;

    let total = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(info.total);
    let total_bank = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(info.total_bank);
    let total_cash = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(info.total_cash);

    return (
        <>

        <Grid container spacing={2}>

            <Grid item xs={4}> 
                <Card className={classes.card}>
                    <div className={classes.main}>
                        <Box width="3em" className="icon">
                            {createElement(EqualizerIcon, { fontSize: 'large' })}
                        </Box>
                        <Box textAlign="right">
                            <Typography className={classes.title} color="textSecondary">{ (info.num_receips > 1 || info.num_receips ==0 ) ? info.num_receips + ' ' + translate('remittances.resume_total_plural') : info.num_receips + ' ' + translate('remittances.resume_total')}</Typography>
                            <Typography variant="h5" component="h2">{total}</Typography>
                        </Box>
                    </div>
                </Card>               
            </Grid> 

            <Grid item xs={4}>
                <Card className={classes.card}>
                    <div className={classes.main}>
                        <Box width="3em" className="icon">
                            {createElement(AccountBalanceIcon, { fontSize: 'large' })}
                        </Box>
                        <Box textAlign="right">
                            <Typography className={classes.title} color="textSecondary">{ (info.num_receips_bank > 1 || info.num_receips_bank === 0) ? info.num_receips_bank + ' ' + translate('remittances.resume_bank_plural') : info.num_receips_bank + ' ' + translate('remittances.resume_bank')}</Typography>
                            <Typography variant="h5" component="h2">{total_bank}</Typography>
                            { (info.num_receips_bank > 0 ) ? 
                                <DownloadSEPAButton remittances_id={id} date_creation={date_creation} /> : <span/>
                            }
                        </Box>
                    </div>
                </Card>
            </Grid> 

            <Grid item xs={4}>
                <Card className={classes.card}>
                    <div className={classes.main}>
                        <Box width="3em" className="icon">
                            {createElement(EuroIcon, { fontSize: 'large' })}
                        </Box>
                        <Box textAlign="right">
                            <Typography className={classes.title} color="textSecondary">{ (info.num_receips_cash > 1 || info.num_receips_cash === 0 ) ? info.num_receips_cash + ' ' + translate('remittances.resume_cash_plural') : info.num_receips_cash + ' ' + translate('remittances.resume_cash')}</Typography>
                            <Typography variant="h5" component="h2">{total_cash}</Typography>
                            { (info.num_receips_cash > 0 ) ? 
                                <DownloadPDFButton remittances_id={id} date_creation={date_creation} /> : <span/>
                            }
                        </Box>
                    </div>
                </Card>
            </Grid> 

        </Grid>

        </>
    )
};

const stylesRemittanceInfo = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

/*
const Empty = ({ remittances_id , date_creation, ...props}) => {
    const translate = useTranslate(); 
    const { basePath, resource } = useListContext();
    return (
        <Box textAlign="center" m={1}>
            <br />
            <Typography variant="h5" paragraph>
                {translate('remittances.no_remittances_users_title')} 
            </Typography>
            <Typography variant="body1">
                {translate('remittances.no_remittances_users_ask')} 
            </Typography>
            <RemittancesUsersCreateButton remittances_id={remittances_id} date_creation={date_creation} />
        </Box>
    );
};*/

const RemittancesUsersFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <SelectInput label={translate('remittances.status')} source="status" choices={[
            { id: 0, name: translate('remittances.statusFilter0')  },
            { id: 1, name: translate('remittances.statusFilter1')  }
        ]} alwaysOn resettable />  
        <SelectInput label={translate('remittances.payment_system')} source="payment_system" choices={[
            { id: 0, name: translate('remittances.bank')  },
            { id: 1, name: translate('remittances.cash')  }
        ]} alwaysOn resettable />  
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
    </Filter>
);
};

const CreateCustomButton = ({remittances_id, ...props}) => {

    console.log('CreateCustomButton ' + remittances_id);

    return (
    <CreateButton
        {...props}
        component={Link}        
        to={{
            pathname: '/remittances_users/create',
            state: { record: { remittances_id: remittances_id } },
        }}
    />
);
};

export function RenderPaymentSystem( record )
{
    const translate = useTranslate(); 

    if (record.payer_payment_cash === 0) 
        return (<Avatar sx={{ width: 35, height: 35 }} ><AccountBalanceIcon/></Avatar>);
    else 
        return (<Avatar sx={{ width: 35, height: 35 }} ><EuroIcon/></Avatar>);

}



export function RenderStatus( record )
{
    const translate = useTranslate(); 

    if (record.status === 0) 
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: green[500] , width: 30, height: 30 }} ><DoneIcon/></Avatar> }
            title={translate('remittances.status0')}
            className="remittanceStatusIcon"
        />);

    else if (record.status === 1) 
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: orange[500] , width: 30, height: 30 }} ><CloseIcon/></Avatar> }
            title={translate('remittances.status1')}
            className="remittanceStatusIcon"
        />);

    else if (record.status === 2)
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: deepOrange[500] , width: 30, height: 30 }} ><CloseIcon/></Avatar> }
            title={translate('remittances.status2')}
            className="remittanceStatusIcon"
        />);

    else if (record.status === 3)
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: deepOrange[500] , width: 30, height: 30 }} ><CloseIcon/></Avatar> }
            title={translate('remittances.status3')}
            className="remittanceStatusIcon"
        />);
}



export const RemittancesUsersItemsDetails = ({ id, record, resource, edit, ...props }) => {
    const translate = useTranslate(); 

    let first_name = record.payer_first_name;
    let last_name_1 = record.payer_last_name_1 ? record.payer_last_name_1 : "";
    let last_name_2 = record.payer_last_name_2 ? record.payer_last_name_2 : "";
    let payer_name = `${first_name} ${last_name_1} ${last_name_2}`; 

    let date_creation = record.date_creation.substring(8,10) + '-' + record.date_creation.substring(5,7) + '-' + record.date_creation.substring(0,4);

    let tamCols = edit ? 3 : 4;

    return (
    <div className="remittanceExpand">
        { edit ? (
        <Grid container spacing={2}>
            <Grid item xs={4}> 
                { !record.users_deleted ? <RemittancesUsersRefreshButton record={record} />:<span /> }
            </Grid>
        </Grid> )
        : "" }
        <Grid container spacing={2}>

            <Grid item xs={tamCols}>                    
                <ListItem>
                  <ListItemText
                    primary={translate('remittances.payer')}
                    secondary={payer_name}
                  />
                </ListItem>                
            </Grid>
            <Grid item xs={tamCols}>
                <ListItem>
                  <ListItemText
                    primary={translate('remittances.nif')}
                    secondary={record.payer_nif}
                  />
                </ListItem>
            </Grid> 
            <Grid item xs={tamCols}>
                <ListItem>
                { record.payer_payment_cash ? (
                  <ListItemText
                    primary={translate('remittances.payment_system')}
                    secondary={translate('remittances.cash')}
                  />
                  ) :
                  (
                  <ListItemText
                    primary={translate('remittances.iban')}
                    secondary={record.payer_iban}
                  />
                  )
                }
                </ListItem>
            </Grid> 
            {edit ? ( 
            <Grid item xs={tamCols}>
                <ListItem>
                  <ListItemText
                    primary={translate('remittances.date_creation')}
                    secondary={date_creation}
                  />
                </ListItem>
            </Grid> 
            ) : "" }

        </Grid>  

        { record.comments && false ? (     
        <Grid container spacing={2}>
            <Grid item xs={12}>                    
                <ListItem>
                  <ListItemText
                    primary={translate('remittances.comment')}
                    secondary={record.comments}
                  />
                </ListItem>
            </Grid>
        </Grid> ) : (<span />) }


    <ReferenceManyField                    
        reference="remittances_users_items"
        target="remittances_users_id"
        label=""
        fullWidth
        sort={{ field: 'id', order: 'ASC' }} 
        perPage="1000"
    >
        { edit ? (
        <EditableDatagrid                       
            createForm={<RemittancesUsersItemsForm initialValues={{ remittances_users_id: id, comment: "" }} />}
            editForm={<RemittancesUsersItemsForm />}
            label=""  
            className="remittanceExpandTable"          
        >
            <TextField source="concept" label={translate('remittances.concept')} sortable={false}  />            
            <NumberField source="quantity" label={translate('remittances.quantity')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />  

        </EditableDatagrid> 
        ):(
        <Datagrid                       
            label=""  
            className="remittanceExpandTable"          
        >
            <TextField source="concept" label={translate('remittances.concept')} sortable={false}  />            
            <NumberField source="quantity" label={translate('remittances.quantity')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />  

        </Datagrid> 
        )}

    </ReferenceManyField>
    <br /> 
    </div>
    );
};


const RemittancesUsersItemsForm= (props) => {
    const translate = useTranslate();   
    const { values } = useFormState();
    return (
    <RowForm {...props}  >
        <TextInput fullWidth source="concept" label={translate('remittances.concept')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />  
        <NumberInput fullWidth source="quantity" label={translate('remittances.quantity')} validate={[required(),validateInteger(translate('common.validation.number'))]} />  
    </RowForm>
);
};



const CustomToolbarCreateRemittance = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const RemittancesCreate = props => {    
    const today = new Date().toISOString().slice(0, 10);
    const translate = useTranslate();   

    let default_remittance_name = translate('remittances.default_name') + today;

    const redirect = (basePath, id, data) => `/remittances/${data.id}`;

    return (
    <Create {...props} >
        <SimpleForm 
            toolbar={<CustomToolbarCreateRemittance />}
            redirect={redirect}
            initialValues={{ associations_id: localStorage.getItem('associations_id') ,type: 0,status: 0,date_creation: today, name: default_remittance_name }}
            
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>                    
                    <Typography variant="h5" className="grey">{translate('remittances.create_remittance')}</Typography>
                </Grid>     
                <Grid item xs={5}>
                    <ReferenceInput 
                        fullWidth
                        sort={{ field: 'priority', order: 'ASC' }} 
                        perPage={1000} 
                        label={translate('ibans.name_create_remittance')} 
                        source="ibans_id" 
                        reference="ibans"
                        filter={{ associations_id : localStorage.getItem('associations_id') }}
                    >
                        <SelectInput optionText="name" optionValue="id" validate={required()} optionText={optionRenderer} />
                    </ReferenceInput> 
                </Grid>
                <Grid item xs={4}>                    
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />
                </Grid>
                <Grid item xs={3}>
                    <DateInput fullWidth source="date_creation" label={translate('remittances.date_creation')} />
                </Grid> 
                <Grid item xs={12}>                    
                    <ReferenceArrayInput
                        source="groups_list"
                        reference="groups_with_quotes"
                        filter={{ associations_id : localStorage.getItem('associations_id') }}
                        perPage={1000}
                    >
                        <CheckboxGroupInput optionText="name" label={translate('remittances.groups_with_quotes')} row={false} className="checkboxGroupInputCompacted" />
                    </ReferenceArrayInput>        
                </Grid>
                <Grid item xs={12}>                    
                    <BooleanInput label={translate('remittances.check_partial_quotes')} source="partial_quotes" />                   
                </Grid>
                <Grid item xs={12}>                    
                    <ReferenceArrayInput
                        source="returns_list"
                        reference="remittances_users_returns"
                        filter={{ associations_id : localStorage.getItem('associations_id') }}
                        perPage={1000}
                    >
                        <CheckboxGroupInput 
                            optionText="first_name" 
                            label={translate('remittances.returns')} 
                            optionText={returnsRenderer}                            
                            row={false}
                            className="checkboxGroupInputCompacted"                            
                        />
                    </ReferenceArrayInput>        
                </Grid>

            </Grid>   

        </SimpleForm>
    </Create>
);
};

const optionRenderer = choice => {
    let str = '';
    if (choice.name) str+=choice.name;   
    //if (choice.ordering) str+=' | ' + choice.ordering;  
    //if (choice.iban) str+=' | ' + choice.iban;
    return str;
};

const returnsRenderer = choice =>
{
    let date_creation = choice.date_creation.substring(8,10) + '-' + choice.date_creation.substring(5,7) + '-' + choice.date_creation.substring(0,4);

    return (<>{date_creation} | {choice.ibans_name} | {choice.name} | <UserFullNameTxt record={choice} /> | {choice.sum_quantity}€ </>);  
} 

const useStyles = makeStyles({
    comment: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '100%',
        display: 'block',
    },
});





const ElementTitle = ({ record }) => {
    const translate = useTranslate(); 
    return <span>{translate('common.edit')} {record ? `"${record.first_name}"` : ''}</span>;
};



