// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  TextInput,
  Button ,
  TopToolbar, required,NumberInput,  ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, SaveButton,
  useUpdate,
  useNotify
    
} from 'react-admin';





import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';



import EditIcon from '@material-ui/icons/Edit';
import Grid from '@mui/material/Grid';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';




import Box from '@material-ui/core/Box';


import { validateMaxLength, validateInteger  } from './../components/Validator';


export const SettingsIbans = (props) => {
  const translate = useTranslate();    
    
  let strRedirect =  '/settings-remittances';  
    
  return (
    <>
      <br/>
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.remittances_desc')}              
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', padding: '20px' }} className="settings_box" >
        <ResourceContextProvider value="ibans">
          <List {...props} 
            basePath="/ibans" 
            sort={{ field: 'priority', order: 'ASC' }} 
            actions={<ListActionsIbansList/>}    
            filter={{ associations_id : localStorage.getItem('associations_id') }}  
            bulkActionButtons={false}  
            pagination={false}
            empty={<ListActionsIbansList />} 
            fullWidth
          >        
            <Datagrid fullWidth>
              <TextField source="name" label={translate('ibans.name')} sortable={false} />   
              <TextField source="ordering" label={translate('ibans.ordering')} sortable={false} />   
              <TextField source="nif" label={translate('ibans.nif')} sortable={false} />   
              <TextField source="iban" label={translate('ibans.iban')} sortable={false} />   
              <TextField source="swift" label={translate('ibans.swift')} sortable={false} />   
              <TextField source="sufix" label={translate('ibans.sufix')} sortable={false} />   
              <TextField source="returns_tax" label={translate('ibans.returns_tax')} sortable={false} />            
              <IbansEditButton label="" />
              <DeleteButton undoable={false} redirect={strRedirect} label=""/>
            </Datagrid>        
          </List>
        </ResourceContextProvider>
      </Box>
    </>
  );
};

const ListActionsIbansList = (props) => {
  const translate = useTranslate(); 
  return (
    <TopToolbar>     
      <IbansCreateButton />      
    </TopToolbar>
  );
};


const IbansCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [create, { loading }] = useCreate('ibans');

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };    

  return (
    <>            
      <Button onClick={handleClick} label={translate('ibans.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('ibans.create')}
        className="dialog-md"
        maxWidth="lg"
      >
        <DialogTitle>{translate('ibans.create')}</DialogTitle>

        <FormWithRedirect
          resource="ibans"
          save={handleSubmit}
          initialValues={ { associations_id : localStorage.getItem('associations_id'), bank_country_iso: 'ES', priority: 0, sufix: '000', ordering : localStorage.getItem('associations_name')  } }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const FormContent = (props) => {

  const translate = useTranslate(); 
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={10}>     
          <TextInput fullWidth source="name" label={translate('ibans.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={2}> 
          <NumberInput fullWidth source="priority" label={translate('common.priority')} />   
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}> 
          <TextInput fullWidth source="ordering" label={translate('ibans.ordering')} validate={[required(),validateMaxLength(150,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={3}> 
          <TextInput fullWidth source="nif" label={translate('ibans.nif')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>                                         
          <TextInput fullWidth source="iban" label={translate('ibans.iban')} validate={[validateMaxLength(32,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={3}> 
          <TextInput fullWidth source="swift" label={translate('ibans.swift')} validate={[validateMaxLength(11,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={3}> 
          <TextInput fullWidth source="sufix" label={translate('ibans.sufix')} validate={[validateMaxLength(3,translate('common.validation.maxLength'))]} />
        </Grid>                     
      </Grid>  
      <Grid container spacing={2}>
        <Grid item xs={4}> 
          <NumberInput fullWidth source="returns_tax" label={translate('ibans.returns_tax')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} />        
        </Grid> 
      </Grid> 
    </DialogContent>
  );
};

/*
<Grid container spacing={2}>                                    
    <Grid item xs={3}> 
        <TextInput fullWidth source="bank_country_iso" label={translate('ibans.bank_country_iso')} validate={[validateMaxLength(2,translate('common.validation.maxLength'))]} disabled /> 
    </Grid> 
</Grid>
*/

const IbansEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'ibans', 
        payload : { id: values.id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('ibans.edit')}
        className="dialog-md"
        maxWidth="lg"
      >
        <DialogTitle>{translate('ibans.edit')}</DialogTitle>

        <FormWithRedirect
          resource="ibans"
          save={handleSubmit}
          initialValues={ props.record }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

