
import * as React from 'react';

import { useState,useCallback, useEffect } from 'react';


import { Layout, AppBar, MenuItemLink, useDataProvider, UserMenu  } from 'react-admin';

import { Link } from 'react-router-dom';
import {useHistory} from 'react-router-dom';






import { Typography } from '@material-ui/core';

import Grid from '@mui/material/Grid';


import PersonIcon from '@mui/icons-material/Person';

import { IconButton, Toolbar } from '@material-ui/core';




import { subscriptionModule  } from '../common/utils';
import { MySearch  } from '../components/MySearch';


import { useTranslate  } from '../common/language';

import MenuItem from '@mui/material/MenuItem';

import SettingsIcon from '@mui/icons-material/Settings';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';

import { ImageViewer } from './../resources/docs';
import { modules  } from './../common/constants';

const ConfigurationMenu = React.forwardRef((props, ref) => {
    
  var translate = useTranslate();
    
  return (
    <MenuItem
      ref={ref}
      component={Link}
      {...props}
      to="/settings-profile"
    >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={translate('settings.profile')} secondary={localStorage.getItem('email')} />
    </MenuItem>
  );
});

const MyUserMenu = props => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Divider />
  </UserMenu>
);

const MyAppBar = props => {

  var translate = useTranslate();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [value, setValue] = React.useState(0);
  const associations_name = localStorage.getItem('associations_name');
    
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let members_group_link = '/groups-tree';
  if (localStorage.getItem('members_group_id') > 0)
    members_group_link = '/groups-tree/' + localStorage.getItem('members_group_id');
    
  const history = useHistory();
  const handleClickSettings = useCallback(() => history.push('/settings'), [history]);
        
  let current_path = history && history.location && history.location.pathname ? history.location.pathname : '';
        
  useEffect(() => {
        
  }, []);
    
  return (
    <>
            
      <AppBar {...props} className="appBar" userMenu={<MyUserMenu />}>
            
        <Grid container wrap="nowrap" className="appTitle">
          <Grid className="imgLogoContainer">
                
            { localStorage.getItem('associations_logo_doc') > 0 ? 
              <ImageViewer docs_id={localStorage.getItem('associations_logo_doc')} className="imgLogo" is_public={false} />
              : 
              window.location.hostname.replaceAll('.','')=='gestioentitatscat' || window.location.hostname.replaceAll('.','')=='localhost' || subscriptionModule(modules.RECERCAT) ?
                <img className="imgLogo" src="/img/gestioentitatscat.png" />
                :
                <img className="imgLogo" src="/img/sifac.png" /> 
                            
            }
                
          </Grid>
          <Grid className="appTitleContainer">
            <Typography variant="h6" >{associations_name}</Typography>
          </Grid>
        </Grid>
            
        { localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ? ( 
                
          <>
            <MySearch targets={[]} />
                
            { subscriptionModule(modules.GESTIO) ?  
              <IconButton 
                onClick={handleClickSettings} >                
                <SettingsIcon />                
              </IconButton>
              : null }
          </>
            
        ) : 
                
          <>
            <IconButton 
              onClick={handleClickSettings} >                
              <SettingsIcon />                
            </IconButton>
          </>
    
        }
            

      </AppBar>
      <Toolbar className="appMenu">
        { localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path=='/dashboard' ? 'selected' : '')} to="/dashboard" key="dashboard" primaryText={translate('main_menu.home') } exact {...props} title={translate('main_menu.home')} />       
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.GESTIO) ? ( 
          <MenuItemLink className={'appMenuNew ' + ((current_path.includes('/users') || current_path.includes('/groups-tree/')) ? 'selected' : '')} key="users" to={members_group_link} primaryText={subscriptionModule(modules.RECERCAT) ? translate('main_menu.recercat_users') : translate('main_menu.users')} exact {...props} title={subscriptionModule(modules.RECERCAT) ? translate('main_menu.recercat_users') : translate('main_menu.users')} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.RECERCAT) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/recercat_centers') ? 'selected' : '')} key="recercat_centers" to="/recercat_centers" primaryText={translate('main_menu.recercat_centers')} exact {...props} title={translate('main_menu.recercat_centers')} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.CONTACTS) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/groups-tree-contacts') ? 'selected' : '')} key="contacts" to="/groups-tree-contacts" primaryText={translate('main_menu.contacts')} exact {...props} title={translate('main_menu.contacts')} />
        ) : <span /> }

        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.GESTIO) && !subscriptionModule(modules.RECERCAT) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/remittances') ? 'selected' : '')} key="remittances" to="/remittances" primaryText={translate('main_menu.remittances')} exact {...props} title={translate('main_menu.remittances')} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.GESTIO) && !subscriptionModule(modules.RECERCAT) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/classrooms') ? 'selected' : '')} key="classrooms" to="/classrooms" primaryText={translate('main_menu.classrooms')} exact {...props} title={translate('main_menu.classrooms')} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.CALENDAR) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/events') ? 'selected' : '')} key="events" to="/events" primaryText={translate('main_menu.events')} exact {...props} title={translate('main_menu.events')} />
        ) : <span /> }
    
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.FEDERATION) && !subscriptionModule(modules.RECERCAT) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/reports') ? 'selected' : '')} key="reports" to="/reports" primaryText={translate('main_menu.reports')} exact {...props} title={translate('main_menu.reports')} />
        ) : <span /> }
        
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.DOCUMENTS_FAC) ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/docs_fac') ? 'selected' : '')} key="docs_fac" to="/docs_fac" primaryText={translate('main_menu.docs_fac')} exact {...props} title={translate('main_menu.docs_fac')} />
        ) : <span /> }
    
        { localStorage.getItem('permissions_groups') === 'SIFAC' ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path=='/dashboard' ? 'selected' : '')} key="" to="/dashboard" primaryText={translate('main_menu.associations')} exact {...props} title={translate('main_menu.associations')} />       
        ) : <span /> }

        { localStorage.getItem('permissions_groups') === 'SIFAC' ? ( 
          <MenuItemLink className={'appMenuNew ' + (current_path.includes('/modules') ? 'selected' : '')} key="modules" to="/modules" primaryText={translate('main_menu.modules')} exact {...props} title={translate('main_menu.modules')} />
        ) : <span /> }
         
      </Toolbar>
    </>
  );
};


export const MyLayout = props => {
  return (
    <Layout 
      {...props} 
      menu={''}
      className={window.location.hostname.replaceAll('.','') + ' ' + (subscriptionModule(modules.RECERCAT) ? 'recercat' : '')}
      appBar={MyAppBar} />
  );
};
