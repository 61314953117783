import * as React from 'react';

import {
  TextField,
  SelectInput,
  TextInput, 
  ReferenceManyField, required, FunctionField
} from 'react-admin';

import { EditableDatagrid, RowForm, } from '@react-admin/ra-editable-datagrid';



import { useFormState } from 'react-final-form';

import { useTranslate  } from '../common/language';

import { validateMaxLength, validateEmail  } from './../components/Validator';

import { SendEmailIconStatus } from './../components/Emails';
import { targetsEmails } from './../common/constants';

export const PermissionsList = (props) => {
    
  const translate = useTranslate();  
    
  return (
    <ReferenceManyField
      fullWidth                
      reference="users_permissions"
      target="associations_id"
      label=""
    >

      <EditableDatagrid   
        createForm={<PermissionsFormNew initialValues={{ associations_id: props.id }} />}
        editForm={<PermissionsFormEdit />}
        hasCreate
        noEdit={false}
      >
        <TextField fullWidth source="user_email" label={translate('permissions.user')} sortable={false} />
        <FunctionField label={translate('users_permissions.role')} render={record => 
          translate('users_permissions.role_' + record.user_permission_public_id)
        } sortable={false}  />
        <FunctionField label={translate('users_permissions.email_sent')} render={record => 
          <SendEmailIconStatus record={record} target={targetsEmails.NEWADMIN}  />
        } sortable={false}  />
                 
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

export const choicesRoles = (translate) => {
  return ([
    { id: 'ADMIN', name: translate('users_permissions.role_ADMIN') },
    { id: 'ADMIN_CONTACTS', name: translate('users_permissions.role_ADMIN_CONTACTS') },
    { id: 'ADMIN_READER', name: translate('users_permissions.role_ADMIN_READER') }
  ]);
};

const PermissionsFormNew= (props) => 
{
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}>
      <TextInput fullWidth source="user_email" label={translate('common.email')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />  
      <SelectInput label={translate('users_permissions.role')} source="user_permission_public_id" choices={choicesRoles(translate)} validate={[required()]} initialValue={'ADMIN'} />            
    </RowForm>
  );
};

const PermissionsFormEdit= (props) => 
{
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}>
      <TextInput fullWidth source="user_email" label={translate('common.email')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} disabled />  
      <SelectInput label={translate('users_permissions.role')} source="user_permission_public_id" choices={choicesRoles(translate)} validate={[required()]} initialValue={'ADMIN'} />            
      <span />
    </RowForm>
  );
};

/*
// No es fa servir la següent funció
const PermissionsFormEdit = (props) => {
  const {
    record,
    id,
    className,
    quitEditMode,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    undoable,
    ...rest
  } = props;
  return (
    <Form
      onSubmit={save}
      {...rest}
    >
      {({ handleSubmit, invalid, dirty }) => (
        <TableRow className={className} key={id}>          
          <TableCell>
            <TextField
              fullWidth
              source="user_email"
              record={record}
              resource={resource}
              basePath={basePath}
            />
          </TableCell>
          <TableCell>
            <PermissionsStatusTextField
              source="status"
              record={record}
              resource={resource}
              basePath={basePath}
            />
          </TableCell>
          <TableCell>            
            <CancelEditButton cancel={quitEditMode} />
          </TableCell>
        </TableRow>
      )}
    </Form>
  );
};

*/


const PermissionsStatusTextField = ({ source,record }) => {
  const translate = useTranslate();  
  const textOutput = (record[source] === 0) ? translate('permissions.status0') : translate('permissions.status1');
  return (<span>{textOutput}</span>);
};
