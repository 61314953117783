import { format } from 'date-fns';
import { ca } from 'date-fns/locale';


export const subscriptionModule = (module,option) => {
    
  let found = false;
    
  if (localStorage.associations_subscriptions)
  {
    var subscriptions = JSON.parse(localStorage.associations_subscriptions);

    if (option == null)
    {
      for (let item of subscriptions) {
        if (item.modules.public_id === module) found = true;
      }
    }
    else
    {
      for (let item of subscriptions) {
        if (item.modules.public_id === module && item.modules_options.public_id === option) found = true;
      }
    }
  }
    
  return found;
};

export const dateEventToCatalan = (dateStart,dateFinish,translate) => {
    
  const dateStartDate = new Date(dateStart);
  const dateFinishDate = new Date(dateFinish);
    
  const dataSenseHora1 = new Date(dateStartDate.getFullYear(), dateStartDate.getMonth(), dateStartDate.getDate());
  const dataSenseHora2 = new Date(dateFinishDate.getFullYear(), dateFinishDate.getMonth(), dateFinishDate.getDate());

  let strDate= '';
  if (dataSenseHora1 < dataSenseHora2 || dataSenseHora1 > dataSenseHora2 ) 
  {
    strDate=translate('common.date_from') + format(dateStartDate, 'EEEE, dd \'de\' MMMM \'de\' yyyy', { locale: ca }) + translate('common.date_to') + format(dateFinishDate, 'dd \'de\' MMMM', { locale: ca });
  } else {
    strDate=format(dateStartDate, 'EEEE, dd \'de\' MMMM \'de\' yyyy', { locale: ca }).replace('di','Di');
  }
    
  return strDate;
};

export const timeEventToCatalan = (dateStart,translate) => {
    
  const dateStartDate = new Date(dateStart);    
  return format(dateStartDate, '\'' + translate('common.time_to') + ' \'HH\':\'mm', { locale: ca });
};
