import React, { useState } from 'react';

import { Link } from 'react-router-dom';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PlaceIcon from '@mui/icons-material/Place';

import Grid from '@mui/material/Grid';

import { MapCatalonia  } from '../components/MapCatalonia';
import { choicesRegion} from './../common/constants';

export const Map = (props) => {

  const [hover, setHover] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
    
  const handleClickMap = (event) => {        
    if (event.target.id)
    {            
      let id = event.target.id.split('-')[1];            
      setHover(id);
            
      if (points.find(itemPoint => itemPoint.id == id).nitems > 0)
        setShowDialog(true);
    }
    else if (event.target.parentElement.id)
    {
      let id = event.target.parentElement.id.split('-')[1];
      setHover(id);
      if (points.find(itemPoint => itemPoint.id == id).nitems > 0)
        setShowDialog(true);
    }
  };
    
  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleMouseEnterMap = (event) => {
    //console.log('handleMouseEnterMap ' + event.target.id);
  };

  const handleMouseOverMap = (event) => {
        
    console.log(event);
    if (event.target.id)
    {  
      let id = event.target.id.split('-')[1];
      setHover(id);
    }
    else if (event.target.parentElement.id)
    {
      let id = event.target.parentElement.id.split('-')[1];
      setHover(id);
    }
    else
    {
      setHover(0);
    }
  };

  const points = [
    {id: 1, x: 360,y: 140, nitems: 0, name: 'DT Girona', pathRef: React.createRef()},
    {id: 2, x: 85,y: 318, nitems: 0, name: 'DT Camp de Tarragona - Terres de l\'Ebre', pathRef: React.createRef()},
    {id: 3, x: 110,y: 150, nitems: 0, name: 'DT Ponent - Alt Pirineu', pathRef: React.createRef()},
    {id: 4, x: 260,y: 252, nitems: 0, name: 'DT Barcelonès', pathRef: React.createRef()},
    {id: 5, x: 233,y: 258, nitems: 0, name: 'DT Baix Llobregat', pathRef: React.createRef()},
    {id: 6, x: 277,y: 225, nitems: 0, name: 'DT Vallesos - Maresme', pathRef: React.createRef()},
    {id: 7, x: 220,y: 160, nitems: 0, name: 'DT Catalunya Central', pathRef: React.createRef()},
    {id: 8, x: 195,y: 235, nitems: 0, name: 'DT Penedès', pathRef: React.createRef()}
  ];
    
  props.data.map(item => points.find(itemPoint => itemPoint.id === item.delegation).nitems++);
        
  return (
    <div className="map">

      <svg
        xmlns="http://www.w3.org/2000/svg"
        ariaLabel="Map of Catalonia"
        onClick={handleClickMap}
        onMouseEnter={handleMouseEnterMap}
        onMouseOver={handleMouseOverMap}
        onMouseDown={e=>e.preventDefault()}
        width="400px"
        height="420px"
      >      
      
        <MapCatalonia points={points} hover={hover} />
      
      
                              
      </svg>  
    
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={props.label_title}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} {...props}>
          {hover && points.find(itemPoint => itemPoint.id == hover).name}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}> 
              <List>
                { props.data.filter(item=>item.delegation==hover).sort((a,b) => a.region - b.region).map(item => {
                        
                  let link = '/users/' + item.users_id + '/show';
                  let secondary = item.address ? item.address : '';
                  secondary += item.city ? ', ' + item.city : '';
                  secondary += item.region ? ' (' + choicesRegion[item.region].name + ')': '';
                        
                  return (
                    <ListItem className="birthdays-list">
                      <ListItemButton component={Link} to={{ pathname: link }}>
                        <ListItemIcon>
                          <PlaceIcon />
                        </ListItemIcon>
                        <ListItemText primary={item.name} secondary={secondary}  />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List><br/>
            </Grid> 
          </Grid>
        </DialogContent>
        
      </Dialog>

      
    </div>
  );
};
