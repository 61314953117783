// in src/Dashboard.js
import React, { useState, useEffect } from 'react';

import { useFormState } from 'react-final-form';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';



import {
  ReferenceInput,
  SelectInput,
  TextInput, required,BooleanInput, useCreate, useRefresh, FormWithRedirect, SaveButton, useDataProvider, Loading,
  useNotify,
  AutocompleteInput

    
} from 'react-admin';

import { validateMaxLength, validateEmail, validateTrue  } from '../components/Validator';

import { parse } from 'query-string';





import { useTranslate  } from '../common/language';
import { RecercatHeader } from './../components/RecercatHeader';



import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export const RecercatAddMember = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [cities, setCities] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users/public/recercat');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { associations_id: associations_id_string } = parse(props.location.search);
  const associations_id = associations_id_string ? parseInt(associations_id_string, 10) : '';
    
  const [open, setOpen] = React.useState(false);
  const [openLopd, setOpenLopd] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
  const openLopdDialog = () => {
    setOpenLopd(true);
  };
  const closeLopdDialog = () => {
    setOpenLopd(false);
  };
    
  useEffect(() => {
    dataProvider.getOne('associations/public', { id: associations_id })
      .then(( { data } ) => {   
                
        setInfo(data);
        
        dataProvider
          .getList('recercat_centers/public/cities', {
            pagination: { page: 0, perPage: 10000 },
            sort: { field: 'name', order: 'ASC' },
            filter: { associations_id: associations_id },
          })
          .then(({ data }) => {
            setCities(data);
            setLoading(false);
            setError(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
                
      })
      .catch(error => {                
        setLoading(false);  
        setError(true);
      });
  }, []);
    
  if (loading) return <Loading />;
  if (error || info && info.public_id !== 'RECERCAT') return (<>
    <RecercatHeader />
    <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box recercat" >
      <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: translate('add_member.recercat_association_not_found') }} />
    </Box>
  </>);
            
  if (!cities) return null;
           
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  if (formSent)
  {
    return (
      <>
        <RecercatHeader />
        <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box recercat" >
          <h1>{translate('recercat_centers.form_title')}</h1>
          <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: info.add_member_form_sent }} />
        </Box>
      </>
    );
  }
    
  if (!info.add_member_actived)
    return <>
      <RecercatHeader />
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box recercat" >
        <h1>{translate('recercat_centers.form_title')}</h1>
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: translate('add_member.form_not_actived') }} />
      </Box>
    </>;
            
  return (
            
    <FormWithRedirect
      resource="users"        
      save={handleSubmit}
      initialValues={{ associations_id : info.id, user_type: 0 }}
      render={formProps => (
        <>     
          <RecercatHeader />
            
          <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box recercat" >   
            <h1>{translate('recercat_centers.form_title')}</h1>
            <Grid container spacing={1} >                
              <Grid item xs={12} className="justify">
                <div dangerouslySetInnerHTML={{ __html: info.add_member_form_text }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput className="add_member_input" label={translate('users.first_name')} fullWidth source="first_name" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput className="add_member_input" label={translate('users.last_name_1')} fullWidth source="last_name_1" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput className="add_member_input" label={translate('users.last_name_2')} fullWidth source="last_name_2" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={12}>
                <TextInput className="add_member_input" fullWidth source="email" label={translate('users.email')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
              </Grid>
              <Grid item xs={12}>      
                <AutocompleteInput 
                  optionText={ (record)=> record.name } 
                  suggestionLimit={1000}
                  resettable={true}
                  clearAlwaysVisible={true}
                  validate={[required()]}
                  fullWidth
                  disabled={false}
                  choices={cities}
                  source="recercat_centers_city"
                  label={translate('recercat_centers.column_city')}
                />
              </Grid>
              <Grid item xs={12}>
                <OptionsRecercatCentersInput associations_id={info.id} source="recercat_centers_id"   />                            
              </Grid> 
              <Grid item xs={12} className="text-right little" >
                {translate('recercat_centers.required_fields')}<br /><br />
              </Grid>
              <Grid item xs={12}>
                <BooleanInput className="add_member_input" fullWidth source="lopd" label={<>{translate('add_member.form_lopd')} <a href='#' onClick={(e)=>{e.preventDefault(); openLopdDialog();}}>{translate('add_member.lopd')}</a></>} validate={[validateTrue(translate('add_member.form_lopd_validator'))]} />
              </Grid>
            </Grid>  
            <br/>                               
            <div className="add_member_button" >
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                label={translate('add_member.recercat_request_send')}
                icon={<span />}
              />
            </div>

          </Box>
          <br /><br />
            
          <BootstrapDialog
            onClose={closeLopdDialog}
            aria-labelledby="customized-dialog-title"
            open={openLopd}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('add_member.lopd')}   
              <IconButton
                aria-label="close"
                onClick={closeLopdDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>   
              <div dangerouslySetInnerHTML={{ __html: info.add_member_lopd }} />
            </DialogContent>
          </BootstrapDialog>
              
          <BootstrapDialog
            onClose={closeErrorDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('add_member.recercat_form_title')}   
              <IconButton
                aria-label="close"
                onClick={closeErrorDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
    
            <DialogContent dividers>
              <Typography gutterBottom>
                {errorApi}
              </Typography>
            </DialogContent>
          </BootstrapDialog>
      
        </>
            
            
      )}
    />
        
  );
  
};

const OptionsRecercatCentersInput = props => {
  const translate = useTranslate();   
  const { values } = useFormState();
    
  return (
    values.recercat_centers_city ? 
      <ReferenceInput fullWidth source="recercat_centers_id" reference="recercat_centers/public"  label={translate('recercat_centers.column_center')} link={false} sortable={false} filter={{ associations_id : props.associations_id, q: values.recercat_centers_city  }} perPage={10000} sort={{ field: 'name', order: 'ASC' }} >
        <SelectInput optionText="name" optionValue="id" resettable validate={[required()]} />  
      </ReferenceInput> : ''
  );
};