// in src/Dashboard.js
import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';

import {        
  SelectInput,
  TextInput,
  required, 
  BooleanInput,
  useCreate, 
  FormWithRedirect, 
  SaveButton, 
  useDataProvider, 
  Loading, 
  DateInput
} from 'react-admin';

import { validateMaxLength, validateEmail, validateTrue  } from '../components/Validator';

import { parse } from 'query-string';


import { useTranslate  } from '../common/language';

import { choicesGenderUser } from '../components/User';
import { ImageViewer } from '../resources/docs';


import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export const AddMember = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users/public');
  const [formSent, setFormSent] = useState();
    
  const { associations_id: associations_id_string } = parse(props.location.search);
  const associations_id = associations_id_string ? parseInt(associations_id_string, 10) : '';
    
  const [open, setOpen] = React.useState(false);
  const [openLopd, setOpenLopd] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
    
  const openLopdDialog = () => {
    setOpenLopd(true);
  };
  const closeLopdDialog = () => {
    setOpenLopd(false);
  };

  useEffect(() => {
    dataProvider.getOne('associations/public', { id: associations_id })
      .then(( { data } ) => {   
        setInfo(data);
        setLoading(false);
        setError(false);
      })
      .catch(error => {                
        setLoading(false);  
        setError(true);
      });
  }, []);
    
  if (loading) return <Loading />;
  if (error) return <center><br /><br/>{translate('add_member.association_not_found')}</center>;
  if (!info) return null;
       
  /*
    const htmlDecode = input => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }*/
    
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  if (formSent)
  {
    return (
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
        <Grid container spacing={1}>
          <Grid item xs={12}>  
            { info.add_member_logo ? 
              <>
                { info.image > 0 ? 
                  <center><ImageViewer docs_id={info.image} className="docs_logo_add_member" is_public={true} /></center>
                  : 
                  <Typography variant="h4" className="grey8" align="center">
                    <br/>{info.name}          
                  </Typography>
                } 
                <br /><br />                            
              </>
              :
              <span/>
            }
          </Grid>
        </Grid>  
                
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: info.add_member_form_sent }} />
                                
      </Box>
    );
  }
    
  if (!info.add_member_actived)
    return <center><br /><br/>{translate('add_member.form_not_actived')}</center>;    
  else
    return (
            
      <FormWithRedirect
        resource="users"        
        save={handleSubmit}
        initialValues={{ associations_id : info.id ,
          user_type: 0
        }}
        render={formProps => (
          <>     
            <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
              <Grid container spacing={1}>
                <Grid item xs={12}>  
                  { info.add_member_logo ? 
                    <>
                      { info.image > 0 ? 
                        <center><ImageViewer docs_id={info.image} className="docs_logo_add_member" is_public={true} /></center>
                        : 
                        <Typography variant="h4" className="grey8" align="center">
                          <br/>{info.name}          
                        </Typography>
                      } 
                      <br />
                      <Typography variant="h5" className="grey8" align="center">
                        {translate('add_member.form_title')}              
                      </Typography>
                      <br /><br />
                    </>
                    :
                    <span/>
                  }
                </Grid>
              </Grid>   
                
              {info.add_member_form_text ?
                <Grid item xs={12} className="justify">
                  <div dangerouslySetInnerHTML={{ __html: info.add_member_form_text }} />
                  <br />
                </Grid>
                : null }
                                      
              <Grid container spacing={1} >
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" label={translate('users.first_name')} fullWidth source="first_name" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" label={translate('users.last_name_1')} fullWidth source="last_name_1" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" label={translate('users.last_name_2')} fullWidth source="last_name_2" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextInput className="add_member_input" fullWidth source="email" label={translate('users.email')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="nif" label={translate('users.nif')} validate={[required(),validateMaxLength(10,translate('common.validation.maxLength'))]} />
                </Grid>
              </Grid>  
                                   
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <TextInput className="add_member_input" fullWidth source="address" label={translate('users.address')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="neighborhood" label={translate('users.neighborhood')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>
              </Grid>
                
              <Grid container spacing={1}>     
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="zip" label={translate('users.zip')} validate={[required(),validateMaxLength(5,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="city" label={translate('users.city')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="region" label={translate('users.region')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>                    
              </Grid>
                   
              <Grid container spacing={1}>   
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="country" label={translate('users.country')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="phone" label={translate('users.phone')} validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="mobile" label={translate('users.mobile')} validate={[required(),validateMaxLength(15,translate('common.validation.maxLength'))]} />
                </Grid>
              </Grid>
                
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <DateInput className="add_member_input" fullWidth source="birthday" label={translate('users.birthday')} validate={[required()]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput className="add_member_input" fullWidth source="job" label={translate('users.job')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput className="add_member_input" fullWidth source="gender" choices={choicesGenderUser} optionText="name" optionValue="id" label={translate('users.gender')} validate={[required()]} />    
                </Grid>
              </Grid>  
                
              { info.add_member_bank_data ? 
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={8}>
                      <TextInput className="add_member_input" fullWidth source="iban" label={translate('users.bank_iban')} validate={[required(),validateMaxLength(32,translate('common.validation.maxLength'))]} />
                    </Grid>                        
                    <Grid item xs={12} sm={4}>
                      <TextInput className="add_member_input" fullWidth source="swift" label={translate('users.bank_swift')} validate={[required(),validateMaxLength(11,translate('common.validation.maxLength'))]} />
                    </Grid>
                  </Grid>
                </>
                    
                :
                <span />
              }
                                
              <Grid spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextInput className="add_member_input" fullWidth multiline source="comment" label={translate('users.add_member_comments')} validate={[validateMaxLength(1000,translate('common.validation.maxLength'))]} />
                </Grid>
              </Grid>
              <Grid spacing={2}>
                <Grid item xs={12}>
                  <BooleanInput className="add_member_input" fullWidth source="lopd" label={<>{translate('add_member.form_lopd')} <a href='#' onClick={(e)=>{e.preventDefault(); openLopdDialog();}}>{translate('add_member.lopd')}</a></>} validate={[validateTrue(translate('add_member.form_lopd_validator'))]} />
                </Grid>
              </Grid>
                                
              <div className="add_member_button" >
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  label={translate('users.add_member_send')}
                  icon={<span />}
                />
              </div>

            </Box>
            <br /><br />
            
            <BootstrapDialog
              onClose={closeLopdDialog}
              aria-labelledby="customized-dialog-title"
              open={openLopd}
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle sx={{ m: 0, p: 2 }}>
                {translate('add_member.lopd')}   
                <IconButton
                  aria-label="close"
                  onClick={closeLopdDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>   
                <div dangerouslySetInnerHTML={{ __html: info.add_member_lopd }} />
              </DialogContent>
            </BootstrapDialog>
              
            <BootstrapDialog
              onClose={closeErrorDialog}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }}>
                {translate('add_member.form_title')}   
                <IconButton
                  aria-label="close"
                  onClick={closeErrorDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
    
              <DialogContent dividers>
                <Typography gutterBottom>
                  {errorApi}
                </Typography>
              </DialogContent>
            </BootstrapDialog>
      
          </>
            
            
        )}
      />
        
    );
  
};

