import React, { useState, useEffect } from 'react';
import { TextInput, required, useUpdate,
  useNotify, Tab, TabbedShowLayout,
  FormWithRedirect, useRefresh, SaveButton, Button, useDataProvider, BooleanInput, ShowButton, PasswordInput , useResourceContext, useGetList } from 'react-admin';

import Typography from '@material-ui/core/Typography';
import { BoxPhotoUser } from './resources/docs';
import { targets , modules } from './common/constants';

import {useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DraftsIcon from '@mui/icons-material/Drafts';
import SettingsIcon from '@mui/icons-material/Settings';


import { useStyles  } from './common/styles';

import { DocsList  } from './resources/docs';


import BusinessIcon from '@mui/icons-material/Business';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import IconCancel from '@material-ui/icons/Cancel';


import { apiUrl, apiDefaultLanguage, apiOrigin, projectUrl } from './common/constants';

import Grid from '@mui/material/Grid';


import { useTranslate  } from './common/language';
import { subscriptionModule  } from './common/utils';

import { validateMaxLength, validateMinMaxLengthAndCharacters, validateEmail  } from './components/Validator';

export const Settings = () => {
  var translate = useTranslate();
  return (
    <Box sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }} className="settings_box" >
      
      <List>
        { (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) ? ( 
          <ListItem>
            <ListItemButton component={Link} to={{ pathname: '/settings-data' }} >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={translate('settings.data')} secondary={translate('settings.data_desc')} />
            </ListItemButton>
          </ListItem>
        ) : <span /> }
        { !subscriptionModule(modules.RECERCAT) ?
          (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) ? ( 
            <ListItem>
              <ListItemButton component={Link}  to={{ pathname: '/settings-remittances' }} >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={translate('settings.remittances')} secondary={translate('settings.remittances_desc')}/>
              </ListItemButton>
            </ListItem>
          ): <span /> 
          : null }
  
        {(localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) ? ( 
          <ListItem>
            <ListItemButton component={Link} to={{ pathname: '/settings-add-member' }} >
              <ListItemIcon>
                <FormatAlignLeftIcon />
              </ListItemIcon>
              { subscriptionModule(modules.RECERCAT) ?
                <ListItemText primary={translate('settings.recercat_add_member')} secondary={translate('settings.recercat_add_member_desc')}/>
                :
                <ListItemText primary={translate('settings.add_member')} secondary={translate('settings.add_member_desc')}/>
              }
            </ListItemButton>
          </ListItem>
        ) : <span /> }
      
        { !subscriptionModule(modules.RECERCAT) ?
          (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) ? ( 
            <ListItem>
              <ListItemButton component={Link}  to={{ pathname: '/settings-classrooms-requests' }} >
                <ListItemIcon>
                  <MeetingRoomIcon />
                </ListItemIcon>
                <ListItemText primary={translate('settings.classrooms_requests')} secondary={translate('settings.classrooms_requests_desc')}/>
              </ListItemButton>
            </ListItem>
          ) : <span />
          : null }
  
        { !subscriptionModule(modules.RECERCAT) ?
          (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) && subscriptionModule(modules.FEDERATION) ? ( 
            <ListItem>
              <ListItemButton component={Link}  to={{ pathname: '/settings-annuary-form' }} >
                <ListItemIcon>
                  <FormatAlignLeftIcon />
                </ListItemIcon>
                <ListItemText primary={translate('settings.annuary_form')} secondary={translate('settings.annuary_form_desc')}/>
              </ListItemButton>
            </ListItem>
          ) : <span /> 
          : null }
  
        <ListItem>
          <ListItemButton component={Link} to={{ pathname: '/settings-emails' }} >
            <ListItemIcon>
              <DraftsIcon />
            </ListItemIcon>
            <ListItemText primary={translate('settings.emails')} secondary={translate('settings.emails_desc')}/>
          </ListItemButton>
        </ListItem>
          
        { localStorage.getItem('permissions_groups') === 'SIFAC' ? ( 
          <ListItem>
            <ListItemButton component={Link} to={{ pathname: '/settings-sadmin' }} >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={translate('settings.maintenance')} secondary={translate('settings.maintenance_desc')}/>
            </ListItemButton>
          </ListItem>
        ) : <span /> }
                      
        { false && (localStorage.getItem('permissions_groups') === 'ADMIN' || localStorage.getItem('permissions_groups') === 'ADMIN_CONTACTS' || localStorage.getItem('permissions_groups') === 'ADMIN_READER' ) ? ( 
          <ListItem>
            <ListItemButton component={Link} to={{ pathname: '/settings-users' }} >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={translate('settings.users')} secondary={translate('settings.users_desc')}/>
            </ListItemButton>
          </ListItem>
        ) : <span /> }              
          
      </List>
        
    </Box>
  );
  
};

/*
 * { (localStorage.getItem('permissions_groups') === "ADMIN" || localStorage.getItem('permissions_groups') === "ADMIN_CONTACTS" || localStorage.getItem('permissions_groups') === "ADMIN_READER" ) ? ( 
          <ListItem>
            <ListItemButton component={Link} to={{ pathname: '/settings-subscription' }} >
              <ListItemIcon>
                <CardMembershipIcon />
              </ListItemIcon>
              <ListItemText primary={translate('settings.subscription')} secondary={translate('settings.subscription_desc')}/>
            </ListItemButton>
          </ListItem>
          ) : <span /> }      

 */
const updateAssociationInfo = () => {
    
  let authHeaders = new Headers({ 'Accept': 'application/json' });
  authHeaders.set('Content-Type', 'application/json' );
  authHeaders.set('Access-Control-Allow-Origin', apiOrigin );
  authHeaders.set('Authorization', localStorage.getItem('auth'));
  authHeaders.set('Accept-Language', apiDefaultLanguage);

  const request = new Request(apiUrl + '/associations/info/' + localStorage.getItem('associations_id') , {
    method: 'GET',
    headers: authHeaders             
  });

  return fetch(request)
    .then(response => response.json())
    .then(data => {
      localStorage.setItem('associations_name', data.name );
      localStorage.setItem('associations_logo_doc', data.associations_logo_doc );
      window.location.reload(false);
    })
    .catch(() => {
    });
                        
};

export const SettingsData = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
    
  useEffect(() => {
    if (localStorage.getItem('associations_id') !== 0)
    {
      dataProvider.getOne('associations', { id: localStorage.getItem('associations_id') })
        .then(({ data }) => {    
          setInfo(data);
        })
        .catch(error => {                                
        });
    }
  }, []);

  if (!info) return null;
    
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'associations', 
        payload : { id: values.id, data : { 
          name : values.name,
          email : values.email,
          nif : values.nif,
          address : values.address,
          neighborhood : values.neighborhood,
          zip : values.zip,
          city : values.city,
          region : values.region,
          country : values.country,
          phone : values.phone  } }
      },
      {
        onSuccess: ({ data }) => {
          updateAssociationInfo();
          history.push('/settings');
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
    
  return (
        
    <FormWithRedirect
      resource="associations"        
      save={handleSubmit}
      initialValues={{ id : localStorage.getItem('associations_id'), 
        name : info.name,
        email : info.email,
        nif : info.nif,
        address : info.address,
        neighborhood : info.neighborhood,
        zip : info.zip,
        city : info.city,
        region : info.region,
        country : info.country,
        phone : info.phone            
      }}
      render={formProps => (
        <>
          <br/>
          <Typography variant="h5" className="grey8" align="center">
            {translate('settings.data')}              
          </Typography>
          <Box sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
                    
            <Grid container spacing={1}>
              <Grid item xs={7}>                      
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput label={translate('common.name')} fullWidth source="name" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={8}>
                    <TextInput fullWidth source="email" label={translate('users.email')} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput fullWidth source="nif" label={translate('users.nif')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid>  
              </Grid>
              <Grid item xs={5}>
                <BoxPhotoUser target={targets.ASSOCIATION} items_id={localStorage.getItem('associations_id')} edit={true} default_img={1} className="docs_photo_user" />  
              </Grid>
            </Grid>   
                                   
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput fullWidth source="address" label={translate('users.address')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>                    
              <Grid item xs={4}>
                <TextInput fullWidth source="neighborhood" label={translate('users.neighborhood')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={4}>
                <TextInput fullWidth source="zip" label={translate('users.zip')} validate={[validateMaxLength(5,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={4}>
                <TextInput fullWidth source="city" label={translate('users.city')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
              </Grid>
            </Grid>
                   
            <Grid container spacing={1}>   
              <Grid item xs={4}>
                <TextInput fullWidth source="region" label={translate('users.region')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={4}>
                <TextInput fullWidth source="country" label={translate('users.country')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={4}>
                <TextInput fullWidth source="phone" label={translate('users.phone')} validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} />
              </Grid>
            </Grid>

            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />

          </Box>
        </>
            
      )}
    />
    
  );
  
};

export const SettingsProfile = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
  const classes = useStyles();
    
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
        
  const handleSubmit = async values => {
                
    update(
      { 
        type: 'update', 
        resource: 'users_login', 
        payload : { id: localStorage.getItem('user_id'), data : { 
          password : values.password , password_confirmation : values.password_confirmation } }
      },
      {
        onSuccess: ({ data }) => {    
          notify(translate('settings.profile_change_pswd_ok'), 'success');
          history.push('/dashboard');
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
    
  return (
        
    <FormWithRedirect
      resource="users_login"           
      save={handleSubmit}
      initialValues={{             
        email : localStorage.getItem('email'),
        password : '',
        password_confirmation: ''
      }}
      render={formProps => (
        <>
          <br/>
          <Typography variant="h5" className="grey8" align="center">
            {translate('settings.profile')}              
          </Typography>
          <Box sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
                                
            <TabbedShowLayout
              syncWithLocation={false}>
           
              <Tab
                label={translate('settings.profile_user_tab')}
                contentClassName={classes.tab}
                fullWidth
                path=""
              >       
                <br/>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput fullWidth source="email" label={translate('users.email')} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} disabled />
                  </Grid>
                </Grid>           
                
              </Tab>
                
              <Tab
                label={translate('settings.profile_pswd_tab')}
                contentClassName={classes.tab}
                fullWidth
                path=""
              >       
                <br/>
                <Grid container spacing={1}>                        
                  <Grid item xs={6}>
                    <PasswordInput fullWidth source="password" label={translate('settings.profile_password')} validate={[validateMinMaxLengthAndCharacters(translate('common.validation.minMaxLengthAndCharacters'))]} />                            
                  </Grid>
                  <Grid item xs={6}>                            
                  </Grid>
                  <Grid item xs={6}>
                    <PasswordInput fullWidth source="password_confirmation" label={translate('settings.profile_password_confirmation')} validate={[validateMinMaxLengthAndCharacters(translate('common.validation.minMaxLengthAndCharacters'))]} />
                  </Grid>
                  <Grid item xs={6}>                            
                  </Grid>
                </Grid>           
                
              </Tab>
            </TabbedShowLayout>
            <br/><br/>

            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />

          </Box>
        </>
            
      )}
    />
    
  );
  
};


export const SettingsAddMember = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
    
  let formUrl = '/public/add-member?associations_id=' + localStorage.getItem('associations_id');
  if (subscriptionModule(modules.RECERCAT))
  {
    formUrl = '/public/sol-licitud-carnet-recercat?associations_id=' + localStorage.getItem('associations_id');
  }
    
  let formCompleteUrl = projectUrl + '/#' + formUrl;
    
  useEffect(() => {
    if (localStorage.getItem('associations_id') !== 0)
    {
      dataProvider.getOne('associations', { id: localStorage.getItem('associations_id') })
        .then(({ data }) => {    
          setInfo(data);
        })
        .catch(error => {                                
        });
    }
  }, []);

  if (!info) return null;
    
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'associations/add-member', 
        payload : { id: values.id, data : { 
          add_member_actived : values.add_member_actived,
          add_member_logo : values.add_member_logo,
          add_member_bank_data : values.add_member_bank_data      ,
          add_member_notification : values.add_member_notification,
          add_member_lopd : values.add_member_lopd,
          add_member_form_sent : values.add_member_form_sent,
          add_member_form_text : values.add_member_form_text
        } }
      },
      {
        onSuccess: ({ data }) => {
          history.push('/settings');
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
    
  const handleClick = (e) => {
    e.preventDefault();
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
    
  return (
        
    <FormWithRedirect
      resource="associations"        
      save={handleSubmit}
      initialValues={{ id : localStorage.getItem('associations_id'), 
        add_member_actived : info.add_member_actived,
        add_member_logo : info.add_member_logo,
        add_member_bank_data : info.add_member_bank_data,
        add_member_notification : info.add_member_notification,
        add_member_lopd : info.add_member_lopd,
        add_member_form_sent : info.add_member_form_sent,
        add_member_form_text : info.add_member_form_text
      }}
      render={formProps => (
            
        <>
          <br/>
          <Typography variant="h5" className="grey8" align="center">
            { subscriptionModule(modules.RECERCAT) ? translate('settings.recercat_add_member') : translate('settings.add_member')}              
          </Typography> 
            
          <Box sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                      
              </Grid>
            </Grid> 
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                { subscriptionModule(modules.RECERCAT) ?
                  <BooleanInput label={translate('settings.recercat_add_member_actived')} fullWidth source="add_member_actived"  />
                  :
                  <BooleanInput label={translate('settings.add_member_actived')} fullWidth source="add_member_actived"  />
                }
              </Grid>
            </Grid>                   
                
            { !subscriptionModule(modules.RECERCAT) ?
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BooleanInput fullWidth source="add_member_logo" label={translate('settings.add_member_logo')}  />
                </Grid>
              </Grid>  : null }
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="settings_add_member_lopd" fullWidth multiline source="add_member_form_text" label={translate('settings.add_member_form_text')}  />
              </Grid>
            </Grid>
                
            { !subscriptionModule(modules.RECERCAT) ?
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BooleanInput fullWidth source="add_member_bank_data" label={translate('settings.add_member_bank_data')}  />
                </Grid>
              </Grid> : null }
                
            { !subscriptionModule(modules.RECERCAT) ?
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BooleanInput fullWidth source="add_member_notification" label={translate('settings.add_member_notification')}  />
                </Grid>
              </Grid> : null }
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="settings_add_member_lopd" fullWidth multiline source="add_member_lopd" label={translate('settings.add_member_lopd')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="settings_add_member_lopd" fullWidth multiline source="add_member_form_sent" label={translate('settings.add_member_form_sent')}  />
              </Grid>
            </Grid>
                
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
                
            <br/><br/>
            <ShowButton 
              component={Link}
              to={formUrl}
              label={translate('settings.add_member_view_form')}
              target="_blank"
              align="right"
              className
            />   
            <br/><br/><a onClick={handleClick} href="">    
              {translate('settings.add_member_help')}
            </a>
                
            <Dialog
              fullWidth
              open={showDialog}
              onClose={handleCloseClick}
              aria-label={translate('remittances.status_form')}
            >
              <DialogTitle>
                {translate('settings.add_member_help')}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseClick}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
                
              <DialogContent>
                { subscriptionModule(modules.RECERCAT) ? translate('settings.recercat_add_member_help_1') : translate('settings.add_member_help_1') }
                <br/>
                <br/>
                <br/><i>
                    &lt;iframe 
                        style="width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: scroll-y;" 
                        src="{formCompleteUrl}" /&gt;                        
                </i><br/><br/><br/>
              </DialogContent>

            </Dialog>
                
                

          </Box>
        </>
            
      )}
    />
    
  );
  
};


export const SettingsClassroomRequests = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
    
  let formUrl = '/public/classrooms-requests?associations_id=' + localStorage.getItem('associations_id');
  let formCompleteUrl = projectUrl + '/#' + formUrl;
    
  useEffect(() => {
    if (localStorage.getItem('associations_id') !== 0)
    {
      dataProvider.getOne('associations', { id: localStorage.getItem('associations_id') })
        .then(({ data }) => {    
          setInfo(data);
        })
        .catch(error => {                                
        });
    }
  }, []);

  if (!info) return null;
    
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'associations/classrooms-requests', 
        payload : { id: values.id, data : { 
          classrooms_requests_actived : values.classrooms_requests_actived,
          classrooms_requests_logo : values.classrooms_requests_logo,
          classrooms_requests_text : values.classrooms_requests_text,
          classrooms_requests_notification : values.classrooms_requests_notification,
          classrooms_requests_lopd : values.classrooms_requests_lopd,
          classrooms_requests_form_sent : values.classrooms_requests_form_sent
        } }
      },
      {
        onSuccess: ({ data }) => {
          history.push('/settings');
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
    
  const handleClick = (e) => {
    e.preventDefault();
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
    
  return (
        
    <FormWithRedirect
      resource="associations"        
      save={handleSubmit}
      initialValues={{ id : localStorage.getItem('associations_id'), 
        classrooms_requests_actived : info.classrooms_requests_actived,
        classrooms_requests_logo : info.classrooms_requests_logo,
        classrooms_requests_text : info.classrooms_requests_text,
        classrooms_requests_notification : info.classrooms_requests_notification,
        classrooms_requests_lopd : info.classrooms_requests_lopd,
        classrooms_requests_form_sent : info.classrooms_requests_form_sent
      }}
      render={formProps => (
            
        <>
          <br/>
          <Typography variant="h5" className="grey8" align="center">
            {translate('settings.classrooms_requests')}              
          </Typography>  
            
          <Box sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                      
              </Grid>
            </Grid> 
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput label={translate('settings.classrooms_requests_actived')} fullWidth source="classrooms_requests_actived"  />
              </Grid>
            </Grid>   
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="classrooms_requests_logo" label={translate('settings.classrooms_requests_logo')}  />
              </Grid>
            </Grid>  
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="classrooms_requests_notification" label={translate('settings.classrooms_requests_notification')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="classrooms_requests_lopd" fullWidth multiline source="classrooms_requests_text" label={translate('settings.classrooms_requests_text')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="classrooms_requests_lopd" fullWidth multiline source="classrooms_requests_lopd" label={translate('settings.classrooms_requests_lopd')}  />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="settings_add_member_lopd" fullWidth multiline source="classrooms_requests_form_sent" label={translate('settings.classrooms_requests_form_sent')}  />
              </Grid>
            </Grid>
                
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
                
            <br/><br/>
            <ShowButton 
              component={Link}
              to={formUrl}
              label={translate('settings.classrooms_requests_view_form')}
              target="_blank"
              align="right"
              className
            />   
            <br/><br/><a onClick={handleClick} href="">    
              {translate('settings.classrooms_requests_help')}
            </a>
                
            <Dialog
              fullWidth
              open={showDialog}
              onClose={handleCloseClick}
              aria-label=""
            >
              <DialogTitle>
                {translate('settings.classrooms_requests_help')}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseClick}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
                
              <DialogContent>
                {translate('settings.classrooms_requests_help_1')}
                <br/>
                <br/>
                <br/><i>
                    &lt;iframe 
                        style="width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: scroll-y;" 
                        src="{formCompleteUrl}" /&gt;                        
                </i><br/><br/><br/>
              </DialogContent>

            </Dialog>
                
                

          </Box>
        </>
            
      )}
    />
    
  );
  
};

export const SettingsAnnuaryForm = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
        
  useEffect(() => {
    if (localStorage.getItem('associations_id') !== 0)
    {
      dataProvider.getOne('associations', { id: localStorage.getItem('associations_id') })
        .then(({ data }) => {    
          setInfo(data);
        })
        .catch(error => {                                
        });
    }
  }, []);

  if (!info) return null;
    
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'associations/annuary-form', 
        payload : { id: values.id, data : { 
          annuary_form_logo : values.annuary_form_logo,
          annuary_form_text : values.annuary_form_text,
          annuary_form_notification : values.annuary_form_notification,
          annuary_form_notification_association : values.annuary_form_notification_association,
          annuary_form_notification_num_members : values.annuary_form_notification_num_members,
          annuary_form_lopd : values.annuary_form_lopd,
          annuary_form_sent : values.annuary_form_sent
        } }
      },
      {
        onSuccess: ({ data }) => {
          history.push('/settings');
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
        
  return (
                        
    <FormWithRedirect
      resource="associations"        
      save={handleSubmit}
      initialValues={{ id : localStorage.getItem('associations_id'), 
        annuary_form_logo : info.annuary_form_logo,
        annuary_form_text : info.annuary_form_text,
        annuary_form_notification : info.annuary_form_notification,
        annuary_form_notification_association : info.annuary_form_notification_association,
        annuary_form_notification_num_members : info.annuary_form_notification_num_members,
        annuary_form_lopd : info.annuary_form_lopd,
        annuary_form_sent : info.annuary_form_sent
      }}
      render={formProps => (
            
        <>
          <br/>
          <Typography variant="h5" className="grey8" align="center">
            {translate('settings.annuary_form')}              
          </Typography>  
            
          <Box sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                      
              </Grid>
            </Grid> 
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="annuary_form_logo" label={translate('settings.annuary_form_logo')}  />
              </Grid>
            </Grid>  
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="annuary_form_notification" label={translate('settings.annuary_form_notification')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="annuary_form_notification_association" label={translate('settings.annuary_form_notification_association')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BooleanInput fullWidth source="annuary_form_notification_num_members" label={translate('settings.annuary_form_notification_num_members')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="classrooms_requests_lopd" fullWidth multiline source="annuary_form_text" label={translate('settings.annuary_form_text')}  />
              </Grid>
            </Grid>
                
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="classrooms_requests_lopd" fullWidth multiline source="annuary_form_lopd" label={translate('settings.annuary_form_lopd')}  />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput className="settings_add_member_lopd" fullWidth multiline source="annuary_form_sent" label={translate('settings.annuary_form_sent')}  />
              </Grid>
            </Grid>
                
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />

          </Box>
        </>
            
      )}
    />
    
  );
  
};

export const SettingsNotAvailable = ( props ) => {
    
  var translate = useTranslate();
    
  return (        
    <Box sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper', padding: '25px' }} className="settings_box not_available" >
                
      <Grid container spacing={1}>
        <Grid item xs={12} align="center">
              Opció temporalment no disponible
        </Grid>
      </Grid>   
    
    </Box>
    
  );
  
};

const ResourceName = () => {
  const { resource } = useResourceContext();
  return <>{resource}</>;
};

export const SettingsEmails = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  /*
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();  
    const [update, { loading, error }] = useUpdate();*/
    
  const { data, ids, loading, error } = useGetList(
    'templates',
    { page: 1, perPage: 1000 },
    { field: 'priority', order: 'ASC' },
    { associations_id : localStorage.getItem('associations_id') }
  );
    
  var gestioAvailableTemplates = ['WELCOME', 'RECEIVE_RETURNED', 'RECEIVE_RETURNED_CASH'];
  var federationAvailableTemplates = ['ANNUARY_SEND', 'ANNUARY_REMINDER','ANNUARY_CONFIRM'];
  var digitalCardsAvailableTemplates = ['DIGITAL_CARD_SEND'];
  var calendarAvailableTemplates = ['EVENT_INVITE', 'EVENT_REMINDER'];
  var recercatAvailableTemplates = ['SKELETON', 'RECERCAT_USER_VALIDATE_EMAIL','RECERCAT_CENTER_RESUME','RECERCAT_REQUEST_DENIED_BY_IRMU','RECERCAT_REQUEST_DENIED_BY_CENTER','RECERCAT_CARD_REVOKED_BY_IRMU','RECERCAT_CARD_REVOKED_BY_CENTER','RECERCAT_IRMU_RESUME','RECERCAT_CARD_REVOKED_BY_IRMU_CENTER_DELETED','RECERCAT_USER_REQUEST_KO','RECERCAT_USER_REQUEST_OK'];
    
  if (loading) { return null; /*return <Loading />; */ }
  if (error) { return <p>ERROR</p>; }
            
  return (
        
    <>
      <br/>
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.emails')}              
      </Typography>

      <Box sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
        <List>
          {ids.map(id => {
            let link = '/settings-emails/' + id;
            let public_id = data[id].public_id;
            /* Mostrem els templates a modificar segons les subscripcions de l'entitat */            
            if ( localStorage.getItem('permissions_groups') === 'SIFAC' ||
                 subscriptionModule(modules.GESTIO) && !(subscriptionModule(modules.RECERCAT)) && gestioAvailableTemplates.indexOf(public_id) > -1 ||
                 subscriptionModule(modules.FEDERATION) && federationAvailableTemplates.indexOf(public_id) > -1 ||
                 subscriptionModule(modules.CALENDAR) && calendarAvailableTemplates.indexOf(public_id) > -1 ||
                 subscriptionModule(modules.DIGITAL_CARDS) && digitalCardsAvailableTemplates.indexOf(public_id) > -1 ||
                 subscriptionModule(modules.RECERCAT) && recercatAvailableTemplates.indexOf(public_id) > -1 )
              return(
                <ListItem>
                  <ListItemButton component={Link} to={{ pathname: link }}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary={translate('settings.emails_' + data[id].public_id)} secondary={ localStorage.getItem('permissions_groups') === 'SIFAC' ? translate('settings.emails_' + data[id].public_id + '_desc') : null }  />
                  </ListItemButton>
                </ListItem>
              );
            else
              return null;
          }
          )}
        </List>
      </Box>
    </>
    
  );
  
};


export const SettingsEmailsItem = ( props ) => {
    
  var translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
        
  useEffect(() => {
    dataProvider.getOne('templates', { id: props.match.params.id })
      .then(({ data }) => {    
        setInfo(data);
      })
      .catch(error => {                                
      });
  }, []);

  if (!info) return null;
    
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'templates', 
        payload : { id: values.id, data : { 
          subject : values.subject,
          content : values.content,
          sender_name : values.sender_name,
          sender_email : values.sender_email,
          reply_to : values.reply_to,
          cc : values.cc,
          bcc : values.bcc,
        } }
      },
      {
        onSuccess: ({ data }) => {
          history.push('/settings-emails');
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
    
  const handleCloseClick = () => {
    history.push('/settings-emails');
  };
    
  return (
        
    <FormWithRedirect
      resource="templates"        
      save={handleSubmit}
      initialValues={{ id : info.id, 
        subject : info.subject,
        content : info.content,
        sender_name : info.sender_name,
        sender_email : info.sender_email,
        reply_to : info.reply_to,
        cc : info.cc,
        bcc : info.bcc,
      }}
      render={formProps => (
            
        <>
          <br/>
          <Typography variant="h5" className="grey8" align="center">
            {translate('settings.emails_' + info.public_id )}              
          </Typography>
            
          <Box sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper', padding: '10px' }} className="settings_box" >
                
            <TabbedShowLayout
              syncWithLocation={false}>
           
              <Tab
                label={translate('settings.emails_message')}
                contentClassName={classes.tab}
                fullWidth
                path=""
              >                 
                <br />
                    
                { info.public_id !== 'SKELETON' && info.public_id !== 'SA_SKELETON' ? 
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextInput fullWidth source="subject" label={translate('settings.emails_subject')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                    </Grid>
                  </Grid>  
                  : <span/> }

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput multiline fullWidth source="content" label={translate('settings.emails_content')} />                              
                  </Grid>
                </Grid>
                    
                <Grid item xs={12}>
                  <b>{translate('settings.emails_vars')}</b> <br/>
                  {translate('settings.emails_' + info.public_id + '_vars')}
                  <br /><br/>
                </Grid>

                <DialogActions>
                  <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </DialogActions>
                    
              </Tab>
                
              { info.public_id !== 'SKELETON' && info.public_id !== 'SA_SKELETON' ? 
                <Tab
                  label={translate('settings.emails_attached')}
                  contentClassName={classes.tab}
                  fullWidth
                  path="docs"
                >   
                  <DocsList target={targets.TEMPLATE} items_id={info.id} />
                  <br />
                </Tab> : <span/> }
                
              { info.public_id !== 'SKELETON' && info.public_id !== 'SA_SKELETON' ? 
                <Tab
                  label={translate('settings.emails_headers')}
                  contentClassName={classes.tab}
                  fullWidth
                  path="headers"
                >   
                  <br />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextInput fullWidth source="sender_name" label={translate('settings.emails_headers_sender_name')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                              
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextInput fullWidth source="sender_email" label={translate('settings.emails_headers_sender_email')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />                              
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput fullWidth source="reply_to" label={translate('settings.emails_headers_reply_to')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />                              
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextInput fullWidth source="cc" label={translate('settings.emails_headers_cc')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                              
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput fullWidth source="bcc" label={translate('settings.emails_headers_bcc')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                              
                    </Grid>
                  </Grid>
                    
                  <Grid item xs={12}>
                    <b>{translate('settings.emails_vars')}</b> <br/>
                    {translate('settings.emails_headers_vars')}
                    <br /><br/>
                  </Grid>
                    
                  <DialogActions>
                    <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                      <IconCancel />
                    </Button>
                    <SaveButton
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    />
                  </DialogActions>
                    
                  <br />
                </Tab> : <span/> }
                
            </TabbedShowLayout>
                

          </Box>
        </>
            
      )}
    />
    
  );
  
};
                    


export const SettingsSAdmin = ( props ) => {
    
  var translate = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
  const notify = useNotify();
        
  const handleTemplatesClick = (e) => {
    e.preventDefault();
        
    dataProvider.getOne('templates/copy', { id: 0 })
      .then(({ data }) => {    
        notify(translate('settings.templates_copied'), 'success');
      })
      .catch(error => {   
        notify(error.message, 'error');
      });
        
  };
    
  const handleIbansClick = (e) => {
    e.preventDefault();
        
    dataProvider.getOne('ibans/copy', { id: 0 })
      .then(({ data }) => {    
        notify(translate('settings.done'), 'success');
      })
      .catch(error => {   
        notify(error.message, 'error');
      });
        
  };
            
  return (
        
    <>
      <br/>
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.maintenance')}              
      </Typography>

      <Box sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
        
        <List>
          <ListItem>
            <ListItemButton component="a" href="" onClick={handleTemplatesClick} >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary={translate('settings.emails_copy')} secondary={translate('settings.emails_copy_desc')}  />
            </ListItemButton>
          </ListItem>
                
          <ListItem>
            <ListItemButton component="a" href="" onClick={handleIbansClick} >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary={translate('settings.ibans_copy')} secondary={translate('settings.ibans_copy_desc')}  />
            </ListItemButton>
          </ListItem>
                
        </List>
            
      </Box>
    </>
    
  );
  
};
