// in src/posts.js
import * as React from 'react';

import { 
  Datagrid,
  TextField,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput, 
  TopToolbar,
  ReferenceManyField, required,DateField ,DateInput,FunctionField, AutocompleteInput, downloadCSV
} from 'react-admin';


import { ExportButton } from './../components/ExportButton';
import jsonExport from 'jsonexport/dist';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';





import { useFormState } from 'react-final-form';


import { useTranslate  } from '../common/language';

import { UserAvatarIcon, UserFullNameTxt } from '../components/User';


import { validateMaxLength  } from './../components/Validator';


/* LLISTAT DES DE PAGINA DE USERS */

export const UsersGroupsResponsabilitiesShow = (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_groups_responsabilities"
      target="users_id"
      label=""
      perPage={1000}
    >
      <Datagrid      
        fullWidth
        className="users_remittances_list header_grey6"
      >
        <TextField source="group.name" label={translate('users.group')} sortable={false} />
        <TextField source="responsability.name" label={translate('users_groups.responsability')} sortable={false} />
        <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false}/>
        <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false}/>
        <TextField source="comment" label={translate('common.comment')} sortable={false} />   
      </Datagrid>

    </ReferenceManyField>               
  );
};


export const UsersGroupsResponsabilitiesEdit= (props) => {
  const translate = useTranslate();
  let users_id = props.id;
    
  return (
    <ReferenceManyField                    
      reference="users_groups_responsabilities"
      target="users_id"
      label=""
      perPage={1000}
    >
      <EditableDatagrid                       
        createForm={<GroupResponsabilityFormNew initialValues={{ users_id: users_id, comment: '', date_join: props.today }} />}
        editForm={<GroupResponsabilityForm userId={{users_id}} />}
        label=""
        hasCreate
        fullWidth                        
      >
        <TextField source="group.name" label={translate('users.group')} sortable={false} />
        <TextField source="responsability.name" label={translate('users_groups.responsability')} sortable={false} />
        <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false} />
        <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false} />
        <TextField source="comment" label={translate('common.comment')} sortable={false} />   
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};


const GroupResponsabilityForm = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >
      <ReferenceInput label={translate('users.group')} source="groups_id" reference="groups" filter={{ users_id : values.id, associations_id : localStorage.getItem('associations_id') }} perPage={1000} >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} disabled />
      </ReferenceInput> 
      <ReferenceInput label={translate('users_groups.responsability')} source="responsabilities_id" reference="responsabilities" perPage={100} sort={{ field: 'priority', order: 'ASC' }} perPage={1000} >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} disabled />
      </ReferenceInput> 
      <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
      <DateInput fullWidth source="date_left" label={translate('users_groups.date_left')} />
      <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};

const GroupResponsabilityFormNew = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >
      <ReferenceInput label={translate('users.group')} source="groups_id" reference="groups" filter={{ users_id : values.id, associations_id : localStorage.getItem('associations_id') }} perPage={1000} >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
      </ReferenceInput> 
      <ReferenceInput label={translate('users_groups.responsability')} source="responsabilities_id" reference="responsabilities" perPage={100} sort={{ field: 'priority', order: 'ASC' }} perPage={1000} >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
      </ReferenceInput> 
      <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
      <span />
      <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};

/* LLISTAT DES DE PAGINA DE GROUPS */


const GroupResponsabilitiesToolbar = ({ groups_id , ...props }) => {

  const translate = useTranslate();
    
  let filterExport = {
    groups_id : groups_id        
  };
    
  return (        
    <TopToolbar>            
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={true} exporter={exporter} resource="users_groups_responsabilities" sort={{ field: 'id', order: 'DESC' }} />
    </TopToolbar>               
  );
};


const exporter = (items, translate) => {
        
  const eventsForExport = items.map(item => {
    var eventForExport = {
      id : item.id,
      first_name : item.user.first_name,             
      last_name_1 : item.user.last_name_1, 
      last_name_2 : item.user.last_name_2, 
      email : item.user.email, 
      group_name : item.group.name, 
      responsability_name: item.responsability.name,
      date_join : item.date_join ? item.date_join.substr(0,10) : '', 
      date_left : item.date_left ? item.date_left.substr(0,10) : '',
      comment: item.comment
    };
    return eventForExport;
  });
  jsonExport(eventsForExport, {
    headers: ['id', 'first_name', 'last_name_1', 'last_name_2','email','group_name','responsability_name','date_join','date_left','comment'],
    rename: [translate('common.id'), translate('users.first_name'), translate('users.last_name_1'), translate('users.last_name_2'), translate('users.email'), translate('groups.group'),  translate('users_groups.responsability'),  translate('users_groups.date_join'),  translate('users_groups.date_left'),  translate('common.comment')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('users_groups.exporter_file_name') ); 
  });
};


export const UsersGroupsResponsabilitiesList = ({ ...props}) => {
    
  const translate = useTranslate();  

  const today = new Date().toISOString().slice(0, 10);
    
  let groups_id = props.groups_id;

  return (
    <SimpleForm basePath="" toolbar={<span/>} {...props}>  
      <GroupResponsabilitiesToolbar groups_id={groups_id} empty={false} />
      <ReferenceManyField   
        fullWidth                 
        reference="users_groups_responsabilities"
        filter={{ groups_id : groups_id }}
        target="irrellevant"
        label=""   
        perPage={1000} 
      >
        <EditableDatagrid                       
          createForm={<GroupResponsabilityFormCreate groups_id={groups_id} initialValues={{ groups_id: groups_id, comment: '', date_join: today }} />}
          editForm={<GroupResponsabilityFormEdit groups_id={groups_id} />}
          label=""
          hasCreate        
                        
          {...props}         
        >
          <FunctionField label="" render={record => <UserAvatarIcon record={record.user} /> } sortable={false} /> 
          <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={record.user} /> } sortable={false} /> 
          <TextField source="responsability.name" label={translate('users_groups.responsability')} sortable={false} />
          <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false}/>
          <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false} />
          <TextField source="comment" label={translate('common.comment')} sortable={false} />   
        </EditableDatagrid>

      </ReferenceManyField> 
    </SimpleForm> 
  );
};

const GroupResponsabilityFormCreate = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();

  return (
    <RowForm {...props}  >

      <span />
      <ReferenceInput 
        label={translate('users.person')}
        source="users_id" 
        reference="users"
        filter={{ groups_id : props.groups_id, associations_id: localStorage.getItem('associations_id') }}             
      >
        <AutocompleteInput fullWidth optionText="complete_name" validate={required()} />
      </ReferenceInput>
      <ReferenceInput 
        label={translate('users_groups.responsability')} 
        source="responsabilities_id" 
        reference="responsabilities"
        sort={{ field: 'priority', order: 'ASC' }} 
        perPage={1000}
      >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
      </ReferenceInput> 
      <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
      <span />
      <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};

const GroupResponsabilityFormEdit = ({...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
    
  console.log('GroupResponsabilityFormEdit');
  console.log(props);
  console.log(values);
    
  return (
    <RowForm {...props}  >

      <span />
      <ReferenceInput 
        label={translate('users.person')}
        source="users_id" 
        reference="users"
        filter={{ groups_id : props.groups_id, associations_id: localStorage.getItem('associations_id') }} 
        perPage={10000}
      >
        <AutocompleteInput fullWidth disabled optionText="complete_name"  validate={required()} />
      </ReferenceInput>
      <ReferenceInput label={translate('users_groups.responsability')} source="responsabilities_id" reference="responsabilities">
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} disabled />
      </ReferenceInput> 
      <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
      <DateInput fullWidth source="date_left" label={translate('users_groups.date_left')} />
      <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};


