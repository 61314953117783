// in src/Dashboard.js
import React, { useState, useEffect } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';




import { RecercatHeader } from './../components/RecercatHeader';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconCancel from '@material-ui/icons/Cancel';

import { useCreate, useRefresh, FormWithRedirect, SaveButton, useDataProvider,
  useNotify
} from 'react-admin';



import { parse } from 'query-string';


import Button from '@material-ui/core/Button';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslate  } from '../common/language';
import { recercatActions  } from './../common/constants';

import { makeStyles } from '@material-ui/core/styles';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';






const useStyles = makeStyles((theme) => ({  
  largeIconOK: {
    '& svg': {
      fontSize: 200,
      color: '#007E12'
    }
  }
  ,
  largeIconKO: {
    '& svg': {
      fontSize: 200,
      color: '#FF7373'
    }
  }
}));


export const RecercatResumeCenter = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [info, setInfo] = useState();
  const [usersGroupsId, setUsersGroupsId] = useState();
  const [action, setAction] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users_groups/public/recercat-actions');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { associations_id: associations_id_string, code: code } = parse(props.location.search);
    
  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
                    
          if (values.action==recercatActions.CENTER_LOGOUT)
          {
            window.close();
          }
          else
          {
            setShowDialog(false);
            window.location.reload();
          }
        },
        onFailure: ( error ) => {
          alert('S\'ha produït un error: ' + error.message);
        }
      }
    );
  };
    
  const handleCloseClick = () => {
    setShowDialog(false);
  };
    
  const doAction = (id,action) => {       
        
    console.log(id);
    console.log(action);
        
    setUsersGroupsId(id);
    setAction(action);
    setShowDialog(true);
  };
    
  useEffect(() => {
    dataProvider.getOne('recercat_centers/public/resume/' + associations_id_string, { id: code })
      .then(( { data } ) => { 
        setInfo(data);    
      })
      .catch(error => {      
        setError(true);               
      });
  }, []);
            
  // Mostra error de validació
  if (error) return (
    <>
      <RecercatHeader />
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: '#ffffff', padding: '0px' }} className="add_member_box recercat" >
        <h1>{translate('recercat_centers.resume_title')}</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">  
            <div dangerouslySetInnerHTML={{ __html: translate('recercat_centers.resume_error') }} />
            <br />
            <br />
          </Grid>
        </Grid>                 
      </Box></>);
        
  if (!info) return null;
            
  return (
    <>
      <RecercatHeader />
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: '#ffffff', padding: '0px' }} className="add_member_box recercat" >
        <Grid item xs={12} align="right" className="pt-0">
          <Button size="small" className="" variant="contained" onClick={() => {doAction(0,recercatActions.CENTER_LOGOUT);}}>{translate('recercat_centers.resume_actions_logout')}</Button>
        </Grid>
        <br />
        <h1>{translate('recercat_centers.resume_title')}</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center" className="pt-0">  
            {info.name}
          </Grid>   
        </Grid>  
        <br /><br />
        <h2>{translate('recercat_centers.resume_pending_center')} ({info.list_group_pending_center.length})</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} className="">  
            <UsersGrid data={info.list_group_pending_center} type={0} doAction={doAction} />
          </Grid>   
        </Grid>   
        <h2>{translate('recercat_centers.resume_pending_irmu')} ({info.list_group_pending_irmu.length})</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} className="">  
            <UsersGrid data={info.list_group_pending_irmu} type={1} doAction={doAction}/>
          </Grid>   
        </Grid>
        <h2>{translate('recercat_centers.resume_validated')} ({info.list_group_members.length})</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} className="">  
            <UsersGrid data={info.list_group_members} type={2} doAction={doAction}/>
          </Grid>   
        </Grid>
      </Box>
        
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('recercat_centers.resume_dialog_title_' + action)}
      >
        <DialogTitle>{translate('recercat_centers.resume_dialog_title_' + action)}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: associations_id_string, users_groups_id : usersGroupsId, action: action, center_hash: code }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    {translate('recercat_centers.resume_dialog_desc_'+action)}
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseClick} >
                  <IconCancel />&nbsp;&nbsp;{translate('ra.action.cancel')}
                </Button>
                <SaveButton 
                  icon={<CheckIcon />}
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={false}
                  disabled={false}
                  label={translate('recercat_centers.resume_dialog_button_'+action)}
                  className="btn-action"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
            
    </>
  );
    
    
};

const UsersGrid = (props) => {

  const translate = useTranslate();  
    
  return (
    <>
      {props.data.length ?
        <TableContainer component={Paper} className="table-container-recercat-centers" >
          <Table size="small" aria-label="a dense table" className="recercat-centers">
            <TableHead>
              <TableRow>
                <TableCell className="th">Nom i cognoms</TableCell>
                <TableCell className="th">Correu-e</TableCell>
                {props.type==2 ? <TableCell className="th">Número</TableCell> : null }
                <TableCell className="th">Accions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell >
                    {row.firstName} {row.lastName1} {row.lastName2}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  {props.type==2 ? <TableCell>{row.numMember}</TableCell> : null }
                  <TableCell>
                    {props.type==0 ? <>
                      <Button size="small" className="btn-green mr-10" variant="contained" onClick={() => {props.doAction(row.userGroupId,recercatActions.CENTER_VALIDATE);}}>{translate('recercat_centers.resume_actions_accept')}</Button>
                      <Button size="small" className="btn-error" variant="contained" onClick={() => {props.doAction(row.userGroupId,recercatActions.CENTER_DENY);}}>{translate('recercat_centers.resume_actions_deny')}</Button>
                    </> : null}
                    
                    {props.type==1 ? <>
                      <Button size="small" className="btn-error" variant="contained" onClick={() => {props.doAction(row.userGroupId,recercatActions.CENTER_DENY);}}>{translate('recercat_centers.resume_actions_deny')}</Button>
                    </> : null}
                    
                    {props.type==2 ? <>
                      <Button size="small" className="btn-error" variant="contained" onClick={() => {props.doAction(row.userGroupId,recercatActions.CENTER_REVOKE);}}>{translate('recercat_centers.resume_actions_revoke')}</Button>
                    </> : null}
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        : null }
      <br /><br />
    
    </>
  );
};
