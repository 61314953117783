// in src/posts.js
import React from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton, 
  ReferenceManyField,
  ResourceContextProvider
} from 'react-admin';


import { UsersAddressesCreateButton, UsersAddressesEditButton } from './../components/UsersAddressesCreateButton';

import { targets } from './../common/constants';


import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';




import Grid from '@mui/material/Grid';





import { useTranslate  } from '../common/language';





export const UsersAddressesShow = (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_addresses"
      target="users_id"
      label=""
      sort={{ field: 'priority', order: 'ASC' }}
    >
      <Datagrid                              
        className="users_remittances_list header_grey6"
      >
        <TextField source="name" label={translate('common.name')} sortable={false} />
        <TextField source="address" label={translate('users.address')} sortable={false} /> 
        <TextField source="zip" label={translate('users.zip')} sortable={false} /> 
        <TextField source="city" label={translate('users.city')} sortable={false} /> 
      </Datagrid>

    </ReferenceManyField>                         
  );
};


export const UsersAddressesEdit = (props) => {
  const translate = useTranslate();
  let users_id = props.id;
  let strRedirect =  `/users/${users_id}/more`;  
  let sort = { field: 'priority', order: 'ASC' };
    
  return (
    <ResourceContextProvider value="users_addresses">
      <List              
        basePath="/users_addresses" 
        filter={{ target : targets.USER, users_id : users_id }}
                    
        actions={<UsersAddressesCreateButton users_id={users_id} label={translate('users_federation.addresses_create')} /> } 
        empty={<UsersAddressesCreateButton users_id={users_id} label={translate('users_federation.addresses_create')} /> }     
        sort={sort} 
        label=""  
        title=""  
        bulkActionButtons={false} 
        pagination={false}
        perPage={1000}
      >
        <Datagrid>                    
          <TextField source="name" label={translate('common.name')} sortable={false} /> 
          <TextField source="address" label={translate('users.address')} sortable={false} /> 
          <TextField source="zip" label={translate('users.zip')} sortable={false} /> 
          <TextField source="city" label={translate('users.city')} sortable={false} /> 
          <UsersAddressesEditButton />
          <DeleteButton undoable={false} redirect={strRedirect} label="" />      
        </Datagrid>                            
      </List>

    </ResourceContextProvider>               
                          
  );
};

export const UserAddressDetail = ({ id, record, resource, edit, ...props }) => {
  const translate = useTranslate(); 
    
  return (
    <div className="">
      <Grid container spacing={2}>
           
        <Grid item xs={6}>
          <ListItem>
            <ListItemText
              primary={translate('users.address')}
              secondary={record.address}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.zip')}
              secondary={record.zip}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.city')}
              secondary={record.city}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.region')}
              secondary={record.region}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users_federation.province')}
              secondary={record.province}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.country')}
              secondary={record.country}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users_federation.gmaps')}
              secondary={record.gmaps}
            />
          </ListItem>
        </Grid> 
        <Grid item xs={6}>                
          <ListItem>
            <ListItemText
              primary={translate('users_federation.ship_address')}
              secondary={record.ship_address}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.zip')}
              secondary={record.ship_zip}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.city')}
              secondary={record.ship_city}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.region')}
              secondary={record.ship_region}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users_federation.province')}
              secondary={record.ship_province}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={translate('users.country')}
              secondary={record.ship_country}
            />
          </ListItem>
        </Grid> 

      </Grid>  

      <br /> 
    </div>
  );
};



