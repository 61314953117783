// in src/posts.js
import * as React from 'react';

import { 
  Datagrid,
  TextField,
  TextInput,
  ReferenceManyField, required,DateField ,DateInput
} from 'react-admin';



import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';





import { useFormState } from 'react-final-form';


import { useTranslate  } from '../common/language';



import { validateMaxLength  } from './../components/Validator';
import { usersEditedBooksType  } from './../common/constants';


/* LLISTAT DES DE PAGINA DE USERS */

export const UsersEditedBooksShow = (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_edited_books"
      target="users_id"
      label=""
      filter={{ type : props.type }}
      sort={{ field: 'datePublication', order: 'ASC' }}
      perPage={1000}
    >
      <Datagrid    
        className="users_remittances_list header_grey6"
      >
        <TextField source="name" label={translate('common.name')} sortable={false}/>
        { (props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY) ? 
          <DateField source="date_publication" label={translate('users_federation.edited_books_date')} sortable={false}/>
          : null }
        { !(props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY) ? 
          <TextField source="period" label={translate('users_federation.edited_books_period')} sortable={false} />
          : null }
      </Datagrid>

    </ReferenceManyField>                  
  );
};

export const UsersEditedBooksEdit= (props) => {
  const translate = useTranslate();
  let users_id = props.id;
    
  return (
    <ReferenceManyField                    
      reference="users_edited_books"
      target="users_id"
      label=""
      filter={{ type : props.type }}
      sort={{ field: 'datePublication', order: 'ASC' }}
      perPage={1000}
    >
      <EditableDatagrid                       
        createForm={<UsersEditedBooksForm initialValues={{ users_id: users_id, type: props.type }} />}
        editForm={<UsersEditedBooksForm />}
        label=""
        hasCreate
        fullWidth                        
      >
        <TextField source="name" label={translate('common.title')} sortable={false} />
        { (props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY) ? 
          <DateField source="date_publication" label={translate('users_federation.edited_books_date')} sortable={false} />
          : null }
        { !(props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY) ? 
          <TextField source="period" label={translate('users_federation.edited_books_period')} sortable={false} />
          : null }
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

const UsersEditedBooksForm = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
    
  let type = props.initialValues ? props.initialValues.type : props.record.type;
  return (
    <RowForm {...props}  >                
      <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(), validateMaxLength(100,translate('common.validation.maxLength'))]} />  
      { (type === usersEditedBooksType.BOOKS || type === usersEditedBooksType.BOOK_ENTITY) ? 
        <DateInput source="date_publication" label={translate('users_federation.edited_books_date')} validate={[required()]} />
        : null }
      { !(type === usersEditedBooksType.BOOKS || type === usersEditedBooksType.BOOK_ENTITY) ? 
        <TextInput fullWidth source="period" label={translate('users_federation.edited_books_period')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />   
        : null }
    </RowForm>
  );
};
