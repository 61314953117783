import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
  Button,
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
} from 'react-admin';

import { useTranslate  } from '../common/language';

export const ExportButton = props => {
  const {
    maxResults = 1000,
    alwaysEnabled = false,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    sort: customSort,
    ...rest
  } = props;
  const {
    filterValues,
    resource,
    currentSort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const exporter = customExporter || exporterFromContext;
  const sort = customSort || currentSort;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate(); 
  const handleClick = useCallback(
    event => {
      dataProvider
        .getList(resource, {
          sort: sort,
          filter: filterValues,
          pagination: { page: 1, perPage: maxResults },
        })
        .then(
          ({ data }) =>
          // here, do what you want with the data
          // ...
          // the default implementation is:
            exporter &&
                        exporter(
                          data,
                          translate,
                          fetchRelatedRecords(dataProvider),
                          dataProvider,
                          resource
                        )
        )
        .catch(error => {
          console.error(error);
          notify('ra.notification.http_error', 'warning');
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      currentSort,
      dataProvider,
      exporter,
      filterValues,
      maxResults,
      notify,
      onClick,
      resource
    ]
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
      disabled={total === 0 && !alwaysEnabled}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
  basePath,
  filterValues,
  resource,
  ...rest
}) =>
  rest;

ExportButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filterValues: PropTypes.object,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  alwaysEnabled: PropTypes.bool,
  resource: PropTypes.string,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  icon: PropTypes.element,
};

