import React, { useState , useCallback } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh, TopToolbar, useTranslate, ImageInput, ImageField, FileInput, FileField, useRedirect, Confirm
} from 'react-admin';

import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';


import {maxDocFileSize, apiUrl, apiOrigin, apiDefaultLanguage} from './../common/constants';

export const DocsPhotoButton = ({ target, items_id , label, icon, topToolbar, ...props }) => {

    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('docs');
    const notify = useNotify();
    const refresh = useRefresh();    
    const translate = useTranslate();  
    const classes = useStylesWebcam(props);

    let allowableAllDocs = false;
    let labelInputFile = "";    
    let maxDocFileSizeMin = maxDocFileSize / 1000000;

    let labelButton = label;
    let iconButton = icon ? icon : <IconContentAdd/>;

    if (target==1 || target==2)
    {
        labelInputFile = translate('docs.fieldDoc').replace('%size%',maxDocFileSizeMin);
        allowableAllDocs = true;
    }
    else
    {
        labelInputFile = translate('docs.fieldImg').replace('%size%',maxDocFileSizeMin);
        allowableAllDocs = false;
    }

    const handleClick = () => {
        setShowDialog(true);
        setTimeout( videoStart , 100);
    };

    const handleCloseClick = () => {
        stopVideo();
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    refresh();
                },
                onFailure: ( error ) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    //const target = localStorage.getItem('docs_target');
    //const items_id = localStorage.getItem('docs_items_id');


    // WEBCAM
    var video = document.querySelector("#video");  

    function videoStart()
    {
        video = document.querySelector("#video");  
  
        // Basic settings for the video to get from Webcam  
        const constraints = {  
            audio: false,  
            video: {  
                width: 200 , height: 200  
            }  
        };  
      
        // This condition will ask permission to user for Webcam access  
        if (navigator.mediaDevices.getUserMedia) {  
            navigator.mediaDevices.getUserMedia(constraints)  
                .then(function (stream) {  
                    video.srcObject = stream;  
                })  
                .catch(function (error) {  
                    console.log(error);

                    notify( translate('docs.webcam_error') ,'error'); 
                    setShowDialog(false);
                });  
        }  
    }    
  
    function stopVideo() {  
        video = document.querySelector("#video"); 
        var stream = video.srcObject;  
        var tracks = stream.getTracks();  
  
        for (var i = 0; i < tracks.length; i++) {  
            var track = tracks[i];  
            track.stop();  
        }  
        video.srcObject = null;  
    }  

    const handleClickCapture = () => {
        var canvas = document.getElementById('canvas');  
        var context = canvas.getContext('2d');  
  
        // Capture the image into canvas from Webcam streaming Video element  
        video = document.querySelector("#video");  
        context.drawImage(video, 0, 0);
        //setTimeout( function() {  }, 1000); 
    };


    //const handleClickSave = () => {
        //const dispatch = useDispatch();
        //const redirect = useRedirect();
        //const notify = useNotify();
        //const [loading, setLoading] = useState(false);
        const handleClickSave = () => {
            //dispatch(fetchStart()); // start the global loading indicator 

            var destinationCanvas = document.createElement("canvas");  
            var destCtx = destinationCanvas.getContext('2d');  


  /*          let link = document.getElementById('linkImage');
              var filename = "example.jpg";
              //link.setAttribute('download', 'example.png');
              link.setAttribute('download', filename);
              link.setAttribute('href', destinationCanvas.toDataURL("image/jpg"));
              link.click();
*/

            var canvas = document.getElementById("canvas");
            var img    = canvas.toDataURL("image/png");
            //window.location = img;
            //  return;

            
        /*
            destinationCanvas.height = 200;  
            destinationCanvas.width = 200;  
      
            destCtx.translate(video.videoWidth, 0);  
            destCtx.scale(-1, 1);  
            destCtx.drawImage(document.getElementById("canvas"), 0, 0);  */
      
            // Get base64 data to send to server for upload  
            //var imagebase64data = destinationCanvas.toDataURL("image/png");  
            img = img.replace('data:image/png;base64,', '');  

            let headers = new Headers({ Accept: 'application/json' });
            headers.set('Access-Control-Allow-Origin', apiOrigin );
            headers.set('Authorization', localStorage.getItem('auth'));
            headers.set('Accept-Language', apiDefaultLanguage);
            headers.set('Content-Type', 'application/json');

            const data = { associations_id : localStorage.getItem('associations_id'), target: target, items_id: items_id, content: img };
            fetch(apiUrl + '/docs/photo', { method: 'POST', headers: headers, body: JSON.stringify(data) })
                .then(() => {
                    //alert(1);
                    //notify('Comment approved');
                    //redirect('/comments');
                    stopVideo();
                    setShowDialog(false);
                    refresh();
                })
                .catch((e) => {
                    //alert(2);
                    //notify('Error: comment not approved', { type: 'warning' })
                })
                .finally(() => {
                    //alert(3);
                    //setLoading(false);
                    //dispatch(fetchEnd()); // stop the global loading indicator

                });
        };
    //};

   
/*
    const handleClickSave = () => {
        // Below new canvas to generate flip/mirron image from existing canvas  
        var destinationCanvas = document.createElement("canvas");  
        var destCtx = destinationCanvas.getContext('2d');  
    
        destinationCanvas.height = 500;  
        destinationCanvas.width = 500;  
  
        destCtx.translate(video.videoWidth, 0);  
        destCtx.scale(-1, 1);  
        destCtx.drawImage(document.getElementById("canvas"), 0, 0);  
  
        // Get base64 data to send to server for upload  
        var imagebase64data = destinationCanvas.toDataURL("image/png");  
        imagebase64data = imagebase64data.replace('data:image/png;base64,', '');  

        let headers = new Headers({ Accept: 'application/json' });
        headers.set('Access-Control-Allow-Origin', apiOrigin );
        headers.set('Authorization', localStorage.getItem('auth'));
        headers.set('Accept-Language', apiDefaultLanguage);

        const result = await fetch(apiUrl + '/remittances/pdf/' + remittances_id , { 
          headers: headers
        });

        $.ajax({  
            type: 'POST',  
            url: '/docs/photo',  
            data: { associations_id : localStorage.getItem('associations_id'), target: target, items_id: items_id, file: imagebase64data },
            contentType: 'application/json; charset=utf-8',  
            dataType: 'text',  
            success: function (out) {  
                alert('Image uploaded successfully..');  
            }  
        });  
    };*/

    return (
        <>
            <Button onClick={handleClick} label={labelButton} className="btnPhotoUser btnWebcam">
               {iconButton}
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('docs.webcam')}
            >
                <DialogTitle>{translate('docs.webcam')}</DialogTitle>


                <Grid container spacing={0}>

                    <Grid item xs={6} className="gridWebCam"> 
                        <center><video id="video" playsInline autoPlay className="webCamCapture1" width="200" height="200"></video> 
                        <br/><Button label={translate('docs.webcam_capture')} onClick={handleClickCapture} >
                            <PhotoCameraIcon />
                        </Button></center>
                    </Grid> 

                    <Grid item xs={6} className="gridWebCam"> 
                        <center>
                        <canvas id="canvas" className="webCamCapture2"  width="200" height="200"></canvas> 
                        </center>
                    </Grid> 

                </Grid>

                <DialogActions>
                    <Button label="ra.action.cancel" onClick={handleCloseClick} >
                        <IconCancel />
                    </Button>
                    <Button label={translate('ra.action.save')} onClick={handleClickSave} variant="contained">
                        <IconSave />
                    </Button>
                    <a id="linkImage"></a>
                </DialogActions>

            </Dialog>
        </>
    );
}

const useStylesWebcam = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        marginBottom: 20
    },
    main: (props: Props) => ({
        overflow: 'inherit',
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'right',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#FF6600',
        },
    }),
    title: {},
}));



export const DocsPhotoDeleteButton = ({ target, items_id , label, icon, topToolbar, onSuccess, ...props }) => {

    const [open, setOpen] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('docs');
    const notify = useNotify();
    const refresh = useRefresh();    
    const translate = useTranslate();  
    const classes = useStylesWebcam(props);

    let labelButton = label;
    let iconButton = icon ? icon : <IconContentAdd/>;
    
    const deletePhoto = () => {

        let headers = new Headers({ Accept: 'application/json' });
        headers.set('Access-Control-Allow-Origin', apiOrigin );
        headers.set('Authorization', localStorage.getItem('auth'));
        headers.set('Accept-Language', apiDefaultLanguage);
        headers.set('Content-Type', 'application/json');

        fetch(apiUrl + '/docs/photo?items_id='+items_id+'&target='+target, { method: 'DELETE', headers: headers })
            .then(() => {
                //alert(1);
                //notify('Comment approved');
                //redirect('/comments');
                setShowDialog(false);
                refresh();
                if (onSuccess) onSuccess();
            })
            .catch((e) => {
                alert(2);
                //notify('Error: comment not approved', { type: 'warning' })
            })
            .finally(() => {
                //alert(3);
                //setLoading(false);
                //dispatch(fetchEnd()); // stop the global loading indicator
            });
    };
    
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        deletePhoto();
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} label={labelButton} className="btnPhotoUser btnDeletePhoto">
               {iconButton}
            </Button>

            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('docs.delete_photo_title')}
                content={translate('docs.delete_photo_ask')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />

        </>
    );
}