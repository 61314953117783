// in src/posts.js
import * as React from "react";

import {
    BooleanInput,
    List, 
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanField ,
    ReferenceManyField,
    Pagination,
    TabbedForm,
    FormTab,
    Filter,
    ChipField, SingleFieldList,NumberInput,ImageInput ,ImageField,useListContext

} from 'react-admin';

import inflection from 'inflection';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import RichTextInput from 'ra-input-rich-text';

import { useMediaQuery } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import { i18nProvider, useTranslate  } from '../common/language';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});


const CategoryGrid: FC = props => {
    const { ids, data, basePath } = useListContext();
    const classes = useStyles(props);
    return(
        <Grid container spacing={2} className={classes.root}>
           {ids.map((id: Identifier) => (
                <Grid key={id} xs={12} sm={6} md={6} lg={4} xl={3} item>
                    <Card>
                        <CardMedia
                            image={`https://marmelab.com/posters/cars-1.jpeg`}
                            className={classes.media}
                        />
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                            >
                                {data[id].name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) ;
};


export const ModulesViewList = (props) => {
    const translate = useTranslate(); 
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={20}
        pagination={false}
        component="div"
        actions={false}
    >
        <CategoryGrid />
    </List>
    );
}

/*

export const ModulesViewListOld = (props) => {
    const translate = useTranslate(); 
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            <Datagrid>                   
                <TextField source="name" label={translate('common.name')}/>
                <BooleanField source="actived" label={translate('common.actived')} />
                <BooleanField source="visible" label={translate('common.visible')} />
                <TextField source="priority" label={translate('common.priority')}/>
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
}

const ElementTitle = ({ record }) => {
    const translate = useTranslate(); 
    return <span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>;
};

export const ModulesViewEdit  = ({ id, ...props }) => {

    const translate = useTranslate(); 
    return (

    <Edit title={<ElementTitle />} {...props} id={id} > 
    <TabbedForm>
            <FormTab
                label={translate('subscriptions.module')}
            >       
                <TextInput source="public_id" label={translate('common.name_internal')} />      
                <TextInput source="name" label={translate('common.name')} />
                <TextInput multiline source="description" label={translate('common.description')} />
                <ImageInput source="image" label={translate('common.image')} accept="image/*">
                    <ImageField source="image" title="title" />
                </ImageInput>
                <BooleanInput source="actived" label={translate('common.actived')} />
                <BooleanInput source="visible" label={translate('common.visible')} />
                <NumberInput source="priority" step={1} />
            </FormTab>

            <FormTab
                label={translate('subscriptions.options')}
            >

            <ReferenceManyField {...props}
                fullWidth
                label=""
                reference="modules_options"
                target="modules_id"
                hasCreate
            >
            
                <EditableDatagrid                    
                    undoable
                    createForm={<OptionForm initialValues={{ modules_id: id }} />}
                    editForm={<OptionForm />}
                >
                    <TextField source="public_id" label={translate('common.name_internal')} />
                    <TextField source="name" label={translate('common.name')} />
                    <TextField multiline source="description" label={translate('common.description')} />
                 
                </EditableDatagrid>
                
            </ReferenceManyField>   

            </FormTab>
    </TabbedForm>
    </Edit>
);
};

const OptionForm= props => {
    const translate = useTranslate(); 
    return (
    <RowForm {...props}>
        <TextInput source="public_id" label={translate('common.name_internal')} />
        <TextInput source="name" label={translate('common.name')} />     
        <TextInput multiline source="description" label={translate('common.description')} />   
    </RowForm>
);
};


export const ModulesViewCreate = props => {
    const translate = useTranslate(); 
    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="public_id" label={translate('common.name_internal')} />
            <TextInput source="name" label={translate('common.name')} /> 
            <TextInput source="description" multiline label={translate('common.description')} />
            <ImageInput source="image" label={translate('common.image')} accept="image/*">
                <ImageField source="image" title="title" />
            </ImageInput>
            <BooleanInput source="actived" label={translate('common.actived')}/>
            <BooleanInput source="visible" label={translate('common.visible')}/>
            <NumberInput source="priority" step={1} label={translate('common.priority')} />
        </SimpleForm>
    </Create>
);
};

*/