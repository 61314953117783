import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  useRefresh, TopToolbar, useTranslate, BooleanInput, FormDataConsumer, useUpdate, SelectInput, NumberInput
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import EditIcon from '@material-ui/icons/Edit';


import Grid from '@mui/material/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { choicesRegion, choicesProvince, choicesCountry } from './../common/constants';
import { validateMaxLength  } from './../components/Validator';


export const UsersAddressesCreateButton = ({ users_id , ...props }) => {
  
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_addresses');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {                    
          notify(error.message, 'error');
        }
      }
    );
  };

  return (
    <>
      <TopToolbar>
        <Button onClick={handleClick} >
          <IconContentAdd/>
        </Button>
      </TopToolbar> 

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_federation.addresses_create')}
      >
        <DialogTitle>{translate('users_federation.addresses_create')}</DialogTitle>

        <FormWithRedirect
          resource="users_addresses"
          save={handleSubmit}
          initialValues={{ users_id: users_id, ship_to_same: true }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <AddressDialogContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
            
    </>
  );
};



export const UsersAddressesEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_addresses', 
        payload : { id: values.id, data: values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_federation.addresses_edit')}
      >
        <DialogTitle>{translate('users_federation.addresses_edit')}</DialogTitle>

        <FormWithRedirect
          resource="users_addresses"
          save={handleSubmit}
          initialValues={props.record}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <AddressDialogContent />                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const AddressDialogContent = ({ ...props}) => {

  const translate = useTranslate();  
    
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextInput fullWidth source="name" label={translate('common.name')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />   
        </Grid>
        <Grid item xs={4}>
          <NumberInput fullWidth source="priority" label={translate('common.priority')} />   
        </Grid>
        <Grid item xs={4}>
          <TextInput fullWidth source="address" label={translate('users.address')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
        </Grid>                                    
        <Grid item xs={4}>
          <TextInput fullWidth source="zip" label={translate('users.zip')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
        </Grid>
        <Grid item xs={4}>
          <TextInput fullWidth source="city" label={translate('users.city')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
        </Grid>
        <Grid item xs={4}>
          <SelectInput fullWidth source="region" choices={choicesRegion} optionText="name" optionValue="id" label={translate('users.region')} resettable />    
        </Grid>
        <Grid item xs={4}>
          <SelectInput fullWidth source="province" choices={choicesProvince} optionText="name" optionValue="id" label={translate('users_federation.province')} resettable />   
        </Grid>
        <Grid item xs={4}>
          <SelectInput fullWidth source="country" choices={choicesCountry} optionText="name" optionValue="id" label={translate('users.country')} resettable />   
        </Grid>
        <Grid item xs={12}>
          <TextInput fullWidth source="gmaps" label={translate('users_federation.gmaps')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput fullWidth source="ship_to_same" label={translate('users_federation.ship_to_same')}  />
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.ship_to_same === false && 
                <>
                  <Grid item xs={4}>
                    <TextInput fullWidth source="ship_address" label={translate('users_federation.ship_address')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid>                                    
                  <Grid item xs={4}>
                    <TextInput fullWidth source="ship_zip" label={translate('users.zip')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput fullWidth source="ship_city" label={translate('users.city')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectInput fullWidth source="ship_region" choices={choicesRegion} optionText="name" optionValue="id" label={translate('users.region')} resettable />    
                  </Grid>
                  <Grid item xs={4}>
                    <SelectInput fullWidth source="ship_province" choices={choicesProvince} optionText="name" optionValue="id" label={translate('users_federation.province')} resettable />   
                  </Grid>
                  <Grid item xs={4}>
                    <SelectInput fullWidth source="ship_country" choices={choicesCountry} optionText="name" optionValue="id" label={translate('users.country')} resettable />   
                  </Grid>
                </>
          }
        </FormDataConsumer>
      </Grid>
    </DialogContent>     
  );
};

