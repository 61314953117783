// in src/Dashboard.js
import React, { useState, useEffect } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';



import Carousel from 'react-material-ui-carousel';

import TextField from '@mui/material/TextField';

import Paginador from './Paginador';

import { IconButton } from '@material-ui/core';

import { DateInput, useRefresh, useDataProvider, Loading,
  useNotify

    
} from 'react-admin';



import { parse } from 'query-string';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ArrowBack } from '@material-ui/icons';



import { useTranslate  } from '../common/language';


import {  ImageViewer } from '../resources/docs';

import { dateEventToCatalan, timeEventToCatalan } from '../common/utils';


import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';





import { Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';

import Chip from '@material-ui/core/Chip';

export const PublicEventsList = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  let history = useHistory();
  const location = useLocation();
    
  const searchParams = new URLSearchParams(location.search);    
  const { associations_id: associations_id_string } = parse(props.location.search);    
  const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page')) : 1);
  const [search, setSearch] = useState(searchParams.get('search') ? searchParams.get('search') : '');    
  const [organizer, setOrganizer] = useState(searchParams.get('organizer') ? searchParams.get('organizer') : 0);    
  const [activity, setActivity] = useState(searchParams.get('activity') ? searchParams.get('activity') : 0);    
  const [province, setProvince] = useState(searchParams.get('province') ? searchParams.get('province') : 0);    
  const [region, setRegion] = useState(searchParams.get('region') ? searchParams.get('region') : 0);    
  const [city, setCity] = useState(searchParams.get('city') ? searchParams.get('city') : 0);    
  const [dateStartAfter, setDateStartAfter] = useState(searchParams.get('dateStartAfter') ? searchParams.get('dateStartAfter') : new Date().toISOString().slice(0, 10));
  const [dateStartBefore, setDateStartBefore] = useState(searchParams.get('dateStartBefore') ? searchParams.get('dateStartBefore') : null );
  const [info, setInfo] = useState();
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);  
  const [activities, setActivities] = useState();
  const [organizers, setOrganizers] = useState();
  const [provinces, setProvinces] = useState();
  const [regions, setRegions] = useState();
  const [cities, setCities] = useState();
        
  let events_fac = false;
  let associations_id = 0;
  let url = 'events/public';
  if ( associations_id_string && associations_id_string === 'fac')
  {
    url = 'events/public/fac';
    events_fac = true;
  }
  else
  {
    associations_id = associations_id_string ? parseInt(associations_id_string, 10) : 0;
  }
        
  const updateUrl = (field,value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(field, value);
    if (field !== 'page') searchParams.set('page', 1);
    const newSearch = searchParams.toString();
    history.push({
      pathname: location.pathname,
      search: newSearch
    });
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    updateUrl('page',newPage);
    window.scrollTo(0, 0);        
  };
    
  const handleSubmit = (values) => {
    
  };
        
  var today = new Date().toISOString().slice(0, 10);
    
  const perPage = 6;
    
  useEffect(() => {
        
    dataProvider.getList(url, {
      pagination: { page: page, perPage: perPage },
      sort: { field: 'priority', order: 'ASC' },
      filter: { associations_id: associations_id, 
        organizers_id: organizer, 
        search: search, 
        activities_id: activity, 
        start_gte : dateStartAfter != null && dateStartAfter.length > 0 ? dateStartAfter + 'T00:00:00' : '', 
        start_lte : dateStartBefore != null && dateStartBefore.length > 0? dateStartBefore + 'T23:59:59' : '',
        provinces_id: province,
        regions_id: region,
        cities_id: city,
      } 
    } )
      .then(response => {
        setEvents(response.data);
        setInfo(response);
        setLoading(false);
        setError(false);
      })
      .catch(error => {        
        console.log(error);
        setLoading(false);  
        setError(true);
      });
        
    if (!activities)
    {
      dataProvider.getList('activities', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'priority', order: 'ASC' },
        filter: { } } )
        .then(response => {                
          setActivities(response.data);                
        })
        .catch(error => {   
        });
    }
        
    if (!organizers)
    {
      dataProvider.getList('organizers/public', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { } } )
        .then(response => {                
          setOrganizers(response.data);                
        })
        .catch(error => {   
        });
    }
                
    if (!provinces)
    {
      dataProvider.getList('provinces', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'priority', order: 'ASC' },
        filter: { } } )
        .then(response => {                
          setProvinces(response.data);                
        })
        .catch(error => {   
        });
    }
                            
  }, [ page, search, activity, dateStartAfter, dateStartBefore, organizer, province, region, city ]);
    
  useEffect(() => {
        
    setPage(1);  updateUrl('province',province);
        
    dataProvider.getList('regions', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: { provinces_id: province } } )
      .then(response => {                
        setRegions(response.data);            
        setRegion(0);
                        
        dataProvider.getList('cities', {
          pagination: { page: 1, perPage: 10000 },
          sort: { field: 'name', order: 'ASC' },
          filter: { regions_id: region, provinces_id: province } } )
          .then(response => {                
            setCities(response.data); 
            setCity(0);
          })
          .catch(error => {   
          });
      })
      .catch(error => {   
      });
        
  }, [ province ]);
    
  useEffect(() => {
        
    dataProvider.getList('cities', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'name', order: 'ASC' },
      filter: { regions_id: region, provinces_id: province } } )
      .then(response => {                
        setCities(response.data);   
        setCity(0);
    
        setPage(1);  
        updateUrl('region',region);
            
      })
      .catch(error => {   
      });
    
        
  }, [ region ]);
    
  useEffect(() => {        
    setPage(1);  updateUrl('city',city);
  }, [ city ]);
    
  if (loading) return <><br/><br/><br/><br/><Loading /></>;
  if (error) return (<center><br /><br/>{translate('public_events.association_not_found')}</center>);
        
  if (!events || !activities || !organizers || !provinces || !regions || !cities) return null;
                
  return (
    <Box sx={{ width: '95%', bgcolor: 'background.paper', padding: '1%' }} className="add_member_box" >
      <Grid container spacing={1}>
        <Grid item xs={12}>  
        </Grid>
      </Grid>   
            
      <Form initialValues={ {dateStartAfter: dateStartAfter, dateStartBefore: dateStartBefore}} onSubmit={handleSubmit} >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} className="public-events-filter">
              <Grid item xs={12} md={2}>  
                <TextField value={search} source="search" className="public-events-filter-mt" fullWidth id="standard-basic" label={translate('public_events.search')} variant="standard" placeholder="" onChange={(event) => { setSearch(event.target.value); setPage(1); updateUrl('search',event.target.value); }}/>  
              </Grid>                                      
              <Grid item xs={6} md={2}>  
                <DateInput
                  source="dateStartAfter"
                  label={translate('public_events.filter_date_after')}
                  value={dateStartAfter}
                  onChange={(event) => { setDateStartAfter(event.target.value); setPage(1);  updateUrl('dateStartAfter',event.target.value); } }     
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} md={2}>  
                <DateInput
                  source="dateStartBefore"
                  label={translate('public_events.filter_date_before')}
                  value={dateStartBefore}
                  onChange={(event) => { setDateStartBefore(event.target.value); setPage(1);  updateUrl('dateStartBefore',event.target.value); } }   
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={3}>  
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={activity}
                  onChange={(event) => { setActivity(event.target.value); setPage(1);  updateUrl('activity',event.target.value); }}
                  label={translate('public_events.filter_activity')} 
                  fullWidth
                >
                  <MenuItem value="0">{translate('public_events.filter_activity_all')} </MenuItem>
                  {activities.map((item) => (  
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}                    
                </Select>
              </Grid>  
              { events_fac ? 
                <Grid item xs={12} sm={3}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={organizer}
                    onChange={(event) => { setOrganizer(event.target.value); setPage(1);  updateUrl('organizer',event.target.value); }}
                    label={translate('public_events.filter_organizer')}   
                    fullWidth
                  >
                    <MenuItem value="0">{translate('public_events.filter_organizer_all')} </MenuItem>
                    {organizers.map((item) => (  
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}                    
                  </Select>
                </Grid> : null }
                        
              { events_fac ? 
                <Grid item xs={12} md={2}>  
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={province}
                    onChange={(event) => { setProvince(event.target.value); }}
                    label={translate('public_events.filter_province')} 
                    fullWidth
                  >
                    <MenuItem value="0">{translate('public_events.filter_province_all')} </MenuItem>
                    {provinces.map((item) => (  
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}                    
                  </Select>
                </Grid> : null }
                        
              { events_fac ? 
                <Grid item xs={12} md={3}>  
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={region}
                    onChange={(event) => { setRegion(event.target.value); }}
                    label={translate('public_events.filter_region')} 
                    fullWidth
                  >
                    <MenuItem value="0">{translate('public_events.filter_region_all')} </MenuItem>
                    {regions.map((item) => (  
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}                    
                  </Select>
                </Grid> : null }
                        
              { events_fac && (region > 0 || province > 0) ? 
                <Grid item xs={12} md={3}>  
                            
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={city}
                    onChange={(event) => { setCity(event.target.value); }}
                    label={translate('public_events.filter_city')} 
                    fullWidth
                  >
                    <MenuItem value="0">{translate('public_events.filter_city_all')} </MenuItem>
                    {cities.map((item) => (  
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}                    
                  </Select>
                            
                </Grid> : null }
                        
            </Grid> 
          </form>
        )}
      </Form>
                 
      <Paginador page={page} perPage={perPage} onPageChange={onPageChange} total={info.total} />
      <br /><br/>  
            
      <Grid container spacing={2}>
        {events.map((item) => {  
                        
          let address = '';
          address += item.classrooms_name != null ? item.classrooms_name : '';
          address += item.events_address != null && item.events_address.length > 0 ? (address.length > 0 ? ', ' : '') + item.events_address : '';
          address += item.events_cities_name != null && item.events_cities_name.length > 0 ? (address.length > 0 ? ', ' : '') + item.events_cities_name : ''; 
    
          return (
            <Grid key={item.id} item xs={12} sm={6} lg={4} textAlign="left" className="classrooms_list_item_container">  
              <Card>                            
                            
                { item.images.replace('{','').replace('}','').split(',')[0] !== 'NULL' ?
                  <ImageViewer docs_id={item.images.replace('{','').replace('}','').split(',')[0]} is_public={true} title={item.title} is_div={true} className="public-events-img" />
                  : 
                  null
                }
                            
                <CardContent>
                            
                  <Chip label={item.activities_name} variant="outlined" className="bg-orange bc-orange upper mb-10 color-white text-align-left" />                                
                  <Box display="flex" alignItems="center" className="mb-5">
                    <CalendarTodayIcon className="public-events-icon" />
                    <Typography color="text.secondary" align="justify" className="fs-12 ml-5">
                      {dateEventToCatalan(item.start,item.end,translate)}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" className="mb-5">
                    <AccessTimeIcon className="public-events-icon" />
                    <Typography color="text.secondary" align="justify" className="fs-12 ml-5">
                      {timeEventToCatalan(item.start,translate)}
                    </Typography>
                  </Box>
                            
                  {address.length > 0 ? 
                    <Box display="flex" alignItems="center" className="mb-5">
                      <PlaceIcon className="public-events-icon" />
                      <Typography color="text.secondary" align="justify" className="fs-12 ml-5">
                        {address}
                      </Typography>
                    </Box> : null } 
                                                        
                  <Box display="flex" alignItems="center" className="mb-10">
                    <CorporateFareIcon className="public-events-icon" />
                    <Typography color="text.secondary" align="justify" className="fs-12 ml-5 upper">
                      {item.organizers_name != null ? item.organizers_name : item.associations_name }  
                    </Typography>
                  </Box> 
                            
                  <a href={'/#/public/events?events_id=' + item.id} className="classrooms_list_item_link">
                    <Typography gutterBottom variant="h5" component="div" align="left" className="bold">
                      {item.title}
                    </Typography>
                  </a>
                  <Typography variant="body2" color="text.secondary" align="justify" className="public-events-description">
                    {item.description ? item.description.replace(/<[^>]*>/g, '') : null}
                  </Typography>                            
                </CardContent>
                <CardActions align="right">
                  <Box flexGrow={1} />
                  <a href={'/#/public/events?events_id=' + item.id} className="classrooms_list_item_link"><Button size="small">{translate('public_events.more_info')} </Button></a>
                </CardActions>
              </Card>
            </Grid>   
          );})}
      </Grid>
                        
      <Paginador page={page} perPage={perPage} onPageChange={onPageChange} total={info.total} />
                     
      <br /><br />
    </Box>
  );
};

export const PublicEvent = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  const history = useHistory();
    
  const [info, setInfo] = useState();
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { events_id: events_id_string } = parse(props.location.search);
  const events_id = events_id_string ? parseInt(events_id_string, 10) : '';
    
  const backButtonClick = () => {        
    if (history.length > 2) 
    {
      history.goBack();
    }
    else if (info)
    {
      history.push('/public/events/list?associations_id=' + info.id);
    }
  };
    
  useEffect(() => {
    dataProvider.getOne('events/public', { id: events_id })
      .then(( { data } ) => { 
        setEvents(data);
        
        dataProvider.getOne('associations/public', { id: data.associations_id })
          .then(( { data } ) => { 
            setInfo(data);
            setLoading(false);
            setError(false);
          })
          .catch(error => {                
            setLoading(false);  
            setError(true);
          });
      })
      .catch(error => {                
        setLoading(false);  
        setError(true);
      });
  }, []);
        
  if (loading) return <><br/><br/><br/><br/><Loading /></>;
  if (error) return <center><br /><br/>{translate('public_events.event_not_found')}</center>;
  if (!info || !events) return null;
    
  let address = '';
  address += events.classrooms_name != null ? events.classrooms_name : '';
  address += events.address != null && events.address.length > 0 ? (address.length > 0 ? ', ' : '') + events.address : '';
  address += events.cities_name != null && events.cities_name.length > 0 ? (address.length > 0 ? ', ' : '') + events.cities_name : '';
                    
  return (
            
    <>
                
      <IconButton onClick={backButtonClick} aria-label={translate('public_events.go_back')} className="public-events-back-button mt-20 ml-20">
        <ArrowBack />
      </IconButton>
        
      <Grid container spacing={2} className="mt-0 pd-5">
        
        { events.images && events.images.length > 0 ? 
          <Grid item xs={12} md={5} textAlign="left" className="pd-5">  
                
            { events.images && events.images.length > 1 ? 
              <Carousel className="events_carrousel_photos" fullHeightHover={true}>
                { events.images.map( (item, i) => <div className="events_carrousel_item_container"><ImageViewer docs_id={item.id} className="events_carrousel_item" is_public={true} link={true} link_name={item.name} title={events.title} /></div> ) }
              </Carousel>   
              : null }
                
            { events.images && events.images.length === 1 ? 
              <div className="events_carrousel_photos">
                <ImageViewer docs_id={events.images[0].id} className="events_carrousel_item" is_public={true} link={true} link_name={events.images[0].name} />
              </div>
              : null }
                
          </Grid> : null }
            
        <Grid item xs={12} md={ events.images && events.images.length > 0 ? 7 : 12} textAlign="left" className="">  
          <Chip label={events.activities_name} variant="outlined" className="bg-orange bc-orange upper mb-10 color-white text-align-left" />                                
          <Box display="flex" alignItems="center" className="mb-5">
            <CalendarTodayIcon className="public-events-icon" />
            <Typography color="text.secondary" align="justify" className="fs-12 ml-5">
              {dateEventToCatalan(events.start,events.end,translate)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className="mb-5">
            <AccessTimeIcon className="public-events-icon" />
            <Typography color="text.secondary" align="justify" className="fs-12 ml-5">
              {timeEventToCatalan(events.start,translate)}
            </Typography>
          </Box>
                
          { address.length > 0 ? 
            <Box display="flex" alignItems="center" className="mb-5">
              <PlaceIcon className="public-events-icon" />
              <Typography color="text.secondary" align="justify" className="fs-12 ml-5">
                {address}
              </Typography>
            </Box> : null }  
                
          <Box display="flex" alignItems="center" className="mb-10">
            <CorporateFareIcon className="public-events-icon" />
            <Typography color="text.secondary" align="justify" className="fs-12 ml-5 upper">
              {events.organizers_name != null ? events.organizers_name : events.associations_name }  
            </Typography>
          </Box> 
                
          <Typography gutterBottom variant="h5" component="div" align="left" className="bold">
            {events.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="justify" className="">
            <div className="" spacing={2} dangerouslySetInnerHTML={{ __html: events.description }} />
          </Typography>
        </Grid>
      </Grid> 
        
    </>
  );
};
   