import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useRefresh, TopToolbar, useTranslate, ImageInput, ImageField, FileInput, FileField
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {maxDocFileSize, targets} from './../common/constants';


export const DocsCreateButton = ({ target, items_id , label, icon, topToolbar, btnClassname, onSuccess, ...props }) => {
  
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('docs');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  let allowableAllDocs = false;
  let labelInputFile = '';    
  let maxDocFileSizeMin = maxDocFileSize / 1000000;


  let labelButton = label;
  let iconButton = icon ? icon : <IconContentAdd/>;
    

  if (target==targets.GROUP || target==targets.USER || target==targets.TEMPLATE || target==targets.CLASSROOM_DOC || target==targets.EVENT || target==targets.DOCUMENT_FAC)
  {
    labelInputFile = translate('docs.fieldDoc').replace('%size%',maxDocFileSizeMin);
    allowableAllDocs = true;
  }
  else
  {
    labelInputFile = translate('docs.fieldImg').replace('%size%',maxDocFileSizeMin);
    allowableAllDocs = false;
  }

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
          if (onSuccess) onSuccess();
        },
        onFailure: ( error ) => {                    
          notify(error.message, 'error');
        }
      }
    );
  };

  return (
    <>
      {(topToolbar ? 
        <TopToolbar>
          <Button onClick={handleClick} label={labelButton} className="">
            {iconButton}
          </Button>
        </TopToolbar> 
        :
        <Button onClick={handleClick} label={labelButton} className={btnClassname}>
          {iconButton}
        </Button>
      )}

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('docs.create')}
      >
        <DialogTitle>{translate('docs.create')}</DialogTitle>

        <FormWithRedirect
          resource="docs"
          save={handleSubmit}
          initialValues={{ associations_id : localStorage.getItem('associations_id'), target: target, items_id: items_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                {allowableAllDocs ? ( 
                  <FileInput source="image" label={labelInputFile} accept="" validate={required()} maxSize={maxDocFileSize} >
                    <FileField source="image" title="title" />
                  </FileInput>
                ) : (
                  <ImageInput source="image" label={labelInputFile} accept="image/*" validate={required()} maxSize={maxDocFileSize} >
                    <ImageField fullWidth source="image" title="title" />
                  </ImageInput>
                )}
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
            
    </>
  );
};
