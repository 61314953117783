// in src/Dashboard.js
import React, { useState } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';


import IconCancel from '@material-ui/icons/Cancel';

//import {List as ListMaterial} from '@mui/material/List';
import ListMaterial from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';





import {
  List, 
  Datagrid,
  TextField,
  Button, 
  TopToolbar,DateField ,DateInput,NumberInput,  FunctionField, ResourceContextProvider, useRefresh, FormWithRedirect, SaveButton, useDataProvider, NumberField,
  ReferenceArrayInput,
  SelectArrayInput,
  useNotify, 
  downloadCSV
    
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { ExportButton } from './../components/ExportButton';



import { parse } from 'query-string';




import { useTranslate  } from '../common/language';



import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ListAltIcon from '@mui/icons-material/ListAlt';





import {useHistory} from 'react-router-dom';


import { UserFullNameTxt } from '../components/User';


export const Reports = props => {
    
  const [openAnnuaryDialog, setOpenAnnuaryDialog] = useState(false);
  const [openResponsabilitiesDialog, setOpenResponsabilitiesDialog] = useState(false);
    
  const translate = useTranslate(); 
  const dataProvider = useDataProvider();
  const history = useHistory();
    
  const year = new Date().getFullYear() - 1;
  var today = new Date();
    
  const handleClick = () => {
    setOpenAnnuaryDialog(true);
  };

  const handleCloseClick = () => {
    setOpenAnnuaryDialog(false);
  };
    
  const handleSubmit = async values => {
    history.push('/reports/annuary?year=' + values.year );
  };
    
  const handleResponsabilitiesClick = () => {
    setOpenResponsabilitiesDialog(true);
  };

  const handleCloseResponsabilitiesClick = () => {
    setOpenResponsabilitiesDialog(false);
  };
    
  const handleResponsabilitiesSubmit = async values => {                
    var resp = '';
    if (values.responsabilities) 
      resp = values.responsabilities.join(',');
    history.push('/reports/responsabilities?date_query=' + values.date_query + '&responsabilities=' + resp );
  };
        
  return (
    <>
      <Box sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }} className="settings_box" >
      
        <ListMaterial>
          <ListItem>
            <ListItemButton onClick={handleClick} >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={translate('reports.annuary')} secondary={translate('reports.annuary_desc')} />
            </ListItemButton>
          </ListItem>
          
          <ListItem>
            <ListItemButton onClick={handleResponsabilitiesClick} >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={translate('reports.responsabilities')} secondary={translate('reports.responsabilities_desc')}/>
            </ListItemButton>
          </ListItem>
        </ListMaterial>
      </Box>
    
      <Dialog        
        open={openAnnuaryDialog}
        onClose={handleCloseClick}
        aria-label={translate('reports.annuary')}
      >
        <DialogTitle>{translate('reports.title')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ year: year }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <br />
                {translate('reports.annuary')}
                <br /><br />
                <NumberInput label={translate('common.year')} source="year" step={1} />     
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={false}
                  label={translate('reports.generate')}
                  icon={<ListAltIcon />}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    
      <Dialog            
        fullWidth
        open={openResponsabilitiesDialog}
        onClose={handleCloseResponsabilitiesClick}
        aria-label={translate('reports.responsabilities')}
      >
        <DialogTitle>{translate('reports.title')}</DialogTitle>

        <FormWithRedirect
          save={handleResponsabilitiesSubmit}
          initialValues={{ date_query: today }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <br />
                {translate('reports.responsabilities')}
                <br /><br />
                <Grid container spacing={2}>                            
                  <Grid item xs={6}> 
                    <ReferenceArrayInput 
                      reference="responsabilities" 
                      source="responsabilities"
                      sort={{ field: 'priority', order: 'ASC' }} 
                    >
                      <SelectArrayInput fullWidth source="responsabilities" label={translate('reports.responsabilities_responsability')} optionText="name" />
                    </ReferenceArrayInput>
                  </Grid>
                  <Grid item xs={6}> 
                    <DateInput fullWidth label={translate('reports.responsabilities_date')} source="date_query" />  
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseResponsabilitiesClick} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={false}
                  label={translate('reports.generate')}
                  icon={<ListAltIcon />}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    
    </>                
  );
    
};

export const ReportsAnnuary = props => {
    
  const translate = useTranslate();  
  const [info, setInfo] = useState();
  const [classrooms, setClassrooms] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
        
  const [open, setOpen] = React.useState(false);
    
  const { year: year_string } = parse(props.location.search);
  const year = year_string ? parseInt(year_string, 10) : '';    
            
  return (        
    <>
      <Typography variant="h5" className="grey8 mt-20 mb-20">
        {translate('reports.annuary')} {year}              
      </Typography>
      <ResourceContextProvider value="reports/annuary">
        <List              
          basePath="/reports/annuary" 
          filter={{ associations_id: localStorage.getItem('associations_id'), year: year }}
          actions={<AnnuaryActions year={year} />} 
          empty={null}   
          label=""  
          title=""  
          bulkActionButtons={false} 
          pagination={false}
          perPage={10000}
        >
          <Datagrid>                    
            <TextField source="id" label={translate('common.id')} sortable={false} />   
            <TextField source="group" label={translate('reports.annuary_item_group')} sortable={false} />   
            <TextField source="calc" label={translate('reports.annuary_item_calc')} sortable={false} />   
            <TextField source="name" label={translate('reports.annuary_item_name')} sortable={false} />     
            <NumberField source="value" label={translate('reports.annuary_item_value')} sortable={false} options={{ maximumFractionDigits: 2 }} />     
            <NumberField source="percent" label={translate('reports.annuary_item_percent')} sortable={false} options={{ maximumFractionDigits: 2 }} />   
            <NumberField source="avg" label={translate('reports.annuary_item_avg')} sortable={false} options={{ maximumFractionDigits: 2 }} />   
          </Datagrid>                            
        </List>
      </ResourceContextProvider>
    </>
        
  );
};

const AnnuaryActions = (props) => {
    
  const translate = useTranslate();
  const dataProvider = useDataProvider();    
  const notify = useNotify();
    
  let filterExport = {
    associations_id : localStorage.getItem('associations_id'), 
    year: props.year
  };
                
  return (
    <TopToolbar>        
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={true} exporter={annuaryExporter} sort={{ field: 'id', order: 'ASC' }} /> 
    </TopToolbar>
  );
};

const annuaryExporter = (items, translate) => {
    
  const annuaryItemsForExport = items.map(annuaryItem => {
    var annuaryItemForExport = {
      id : annuaryItem.id,
      group : annuaryItem.group, 
      calc : annuaryItem.calc, 
      name : annuaryItem.name, 
      value : annuaryItem.value, 
      percent: annuaryItem.percent,
      avg: annuaryItem.avg,
    };
    return annuaryItemForExport;
  });
  jsonExport(annuaryItemsForExport, {
    headers: ['id', 'group', 'calc','name', 'value', 'percent','avg'],
    rename: [translate('reports.annuary_item_id'), translate('reports.annuary_item_group'), translate('reports.annuary_item_calc'), translate('reports.annuary_item_name'), translate('reports.annuary_item_value'), translate('reports.annuary_item_percent'), translate('reports.annuary_item_avg')],                
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('reports.annuary_exporter_file_name') ); 
  });
    
};





export const ReportsResponsabilities = props => {
    
  const translate = useTranslate();  
  const [info, setInfo] = useState();
  const [classrooms, setClassrooms] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
        
  const [open, setOpen] = React.useState(false);
    
  const { date_query: date_query_string, responsabilities: responsabilities_string } = parse(props.location.search);
  const date_query = date_query_string ? date_query_string : new Date().toLocaleDateString('en-CA');
    
  const responsabilities = responsabilities_string;
    
  return (        
    <>
      <Typography variant="h5" className="grey8 mt-20 mb-20">
        {translate('reports.responsabilities')}             
      </Typography>
      <ResourceContextProvider value="reports/responsabilities">
        <List              
          basePath="/reports/responsabilities" 
          filter={{ associations_id: localStorage.getItem('associations_id'), date_query: date_query, responsabilities: responsabilities }}
          actions={<ResponsabilitiesActions date_query={date_query} responsabilities={responsabilities} />} 
          empty={null}   
          label=""  
          title=""  
          bulkActionButtons={false}             
          perPage={25}
          empty={<span>{translate('reports.no_results')}</span>}
        >
          <Datagrid>                    
            <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={record} /> } sortable={false} />
            <TextField source="email" label={translate('common.email')} sortable={false} /> 
            <TextField source="responsability_name" label={translate('reports.responsabilities_responsability')} sortable={false} />                         
            <TextField source="entity_name" label={translate('reports.responsabilities_list_entity_name')} sortable={false} />   
            <TextField source="entity_city" label={translate('reports.responsabilities_list_entity_city')} sortable={false} /> 
            <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false} />
            <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false} />   
          </Datagrid>                            
        </List>
      </ResourceContextProvider>
    </>
        
  );
};

const ResponsabilitiesActions = (props) => {
    
  const translate = useTranslate();
  const dataProvider = useDataProvider();    
  const notify = useNotify();
    
  let filterExport = {
    associations_id : localStorage.getItem('associations_id'), 
    date_query: props.date_query,
    responsabilities: props.responsabilities
  };
                
  return (
    <TopToolbar>        
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={true} exporter={responsabilitiesExporter} sort={{ field: 'id', order: 'ASC' }} /> 
    </TopToolbar>
  );
};
                    
const responsabilitiesExporter = (items, translate) => {
    
  const responsabilitiesItemsForExport = items.map(responsabilityItem => {
    var responsabilityItemForExport = {
      first_name : responsabilityItem.first_name,
      last_name_1 : responsabilityItem.last_name_1,
      last_name_2 : responsabilityItem.last_name_2,
      email : responsabilityItem.email,
      responsability_name : responsabilityItem.responsability_name,
      entity_name : responsabilityItem.entity_name,
      entity_city : responsabilityItem.entity_city,
      date_join: responsabilityItem.date_join ? responsabilityItem.date_join.substr(0,10) : '' ,
      date_left: responsabilityItem.date_left ? responsabilityItem.date_left.substr(0,10) : '' ,
    };
    return responsabilityItemForExport;
  });
  jsonExport(responsabilitiesItemsForExport, {
    headers: ['first_name', 'last_name_1', 'last_name_2','email', 'responsability_name', 'entity_name','entity_city','date_join','date_left'],
    rename: [translate('common.first_name'), translate('common.last_name_1'), translate('common.last_name_2'), translate('common.email'), translate('reports.responsabilities_responsability'), translate('reports.responsabilities_list_entity_name'), translate('reports.responsabilities_list_entity_city'),translate('users_groups.date_join'), translate('users_groups.date_left')],                
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('reports.responsabilities_exporter_file_name') ); 
  });
    
};


