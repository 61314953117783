
import { apiUrl, apiDefaultLanguage, apiOrigin } from './../common/constants';
import { Login, LoginForm } from 'react-admin';


function removeStorage()  {
  localStorage.removeItem('auth');
  localStorage.removeItem('associations_id');
  localStorage.removeItem('associations_name');
  localStorage.removeItem('username');
  localStorage.removeItem('email');
  localStorage.removeItem('user_id');
  localStorage.removeItem('permissions_groups');
  localStorage.removeItem('members_group_id');
  localStorage.removeItem('members_group_pending_id');
  localStorage.removeItem('associations_logo_doc');
  localStorage.removeItem('associations_subscriptions');

}

// in src/authProvider.js
const authProvider = {
    
  // called when the user attempts to log in
  login: ({ username, password }) =>  {
        
    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Content-Type', 'application/json' );
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Accept-Language', apiDefaultLanguage);

    const request = new Request(apiUrl + '/auth/login', {
      method: 'PUT',
      body: JSON.stringify({ email: username, pswd: password }),
      headers: headers,
    });

    return fetch(request)
      .then(response => {
        if (response.status === 406)
        {
          throw new Error('El compte està bloquejat. Espera uns minuts i torna a provar-ho');     
        }
        else if (response.status === 405)
        {
          throw new Error('S\'han fet massa intents d\'inici de sessió. Per seguretat el compte ha quedat bloquejat durant 5 minuts.'); 
        }
        else if (response.status === 404) {
          throw new Error('Usuari o contrassenya incorrecta');
        }
        else if (response.status < 200 || response.status >= 300) {
          throw new Error('Usuari o contrassenya incorrecta');
        }                
        return response.json();
      })
      .then(auth => {
        localStorage.setItem('auth', auth.token );
        localStorage.setItem('associations_id', auth.associations_id );
        localStorage.setItem('associations_name', auth.associations_name );
        localStorage.setItem('username', auth.name );
        localStorage.setItem('email', auth.email );
        localStorage.setItem('user_id', auth.users_id );
        localStorage.setItem('permissions_groups', auth.permissions_groups );
        localStorage.setItem('members_group_id', auth.members_group_id );
        localStorage.setItem('members_group_pending_id', auth.members_group_pending_id );
        localStorage.setItem('associations_logo_doc', auth.associations_logo_doc );
        localStorage.setItem('associations_subscriptions', JSON.stringify(auth.associations_subscriptions) );
      })
      .catch((e) => {      
        throw new Error(e);               
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
        
    let authHeaders = new Headers({ Accept: 'application/json' });
    authHeaders.set('Access-Control-Allow-Origin', apiOrigin );
    authHeaders.set('Authorization', localStorage.getItem('auth'));
    authHeaders.set('Accept-Language', apiDefaultLanguage);

    const request = new Request(apiUrl + '/auth/logout', {
      method: 'DELETE',
      headers: authHeaders             
    });

    return fetch(request)
      .then(response => {
        removeStorage();
      })
      .catch(() => {
        removeStorage();
      });

  },
    
  // called when the API returns an error
  checkError: ({ status }) => {        
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};


export default authProvider;

